import { css } from 'styled-components';

export const addUserStyles = () => {
  return css`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* height: 620px; */
    width: 850px;
    /* margin: 20px; */
    /* background-color: #fff; */
    /* box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
    border-radius: 20px; */
    /* background: red; */

    /* .main-container {
      display: flex;
      background: blue; */
    .main-header {
      /* flex: 1; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px;
    }
    .main-content {
      width: 600px;
      /* flex: 2; */
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }
    .main-footer {
      display: flex;
      align-self: flex-end;
      margin-top: 10px;
    }
    /* } */
  `;
};

import React, { useState } from 'react';
import SideBar from '../../components/Drawer';

import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from '@material-ui/core';
import { VictoryPie, VictoryLabel } from 'victory';
//Styles
import styled from 'styled-components';
import { statisticsStyles } from './statistics.styles';
//Icons
import { ic_dashboard } from 'react-icons-kit/md/ic_dashboard';
//Commons
import Text from '../../commons/Text';
const REGIONS = [
  {
    id: 1,
    label: 'region 1',
  },
  {
    id: 2,
    label: 'region 2',
  },
  {
    id: 3,
    label: 'region 3',
  },
];
const DEPARTMENTS = [
  {
    id: 1,
    label: 'departement 1',
  },
  {
    id: 2,
    label: 'departement 2',
  },
  {
    id: 3,
    label: 'departement 3',
  },
];
const COMPAGNES = [
  {
    id: 1,
    label: 'comapgne 1',
  },
  {
    id: 2,
    label: 'comapgne 2',
  },
  {
    id: 3,
    label: 'comapgne 3',
  },
];

const sampleData = [
  { quarter: 1, earnings: 13000 },
  { quarter: 2, earnings: 16500 },
  { quarter: 3, earnings: 14250 },
  { quarter: 4, earnings: 19000 },
];

const Statistics: React.FC<any> = (props) => {
  const [region, setRegion] = useState<any>(null);
  const [department, setDepartment] = useState<any>(null);
  const [compagne, setCompagne] = useState<any>(null);
  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    console.log('name', event.target.name);
    if (event.target.name === 'region') {
      setRegion(event.target.value);
    } else if (event.target.name === 'department') {
      setDepartment(event.target.value);
    } else {
      setCompagne(event.target.value);
    }

    // props.addUserPropsChanged('permission', event.target.value);
  };
  return (
    <SideBar title='Dashboard' icon={ic_dashboard}>
      <Wrapper></Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => statisticsStyles()}
`;

export default Statistics;

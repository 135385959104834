import { SignalCellularNullSharp } from '@material-ui/icons';
import {
  REGION_GET_All_LIST_SUCCESS,
  REGION_GET_All_LIST_FAILED,
  REGION_PROFILE_FILTER_USERS_LIST_CHANGED,
  REGION_PROFILE_GET_REGION_SUCCESS,
  REGION_PROFILE_GET_REGION_FAILED,
  REGION_PROFILE_PROPS_CHANGED,
  REGION_PROFILE_PROPS_VALIDATION,
  REGION_PROFILE_CREATE_REGION_SUCCESS,
  REGION_PROFILE_CREATE_REGION_FAILED,
  REGION_PROFILE_UPDATE_REGION_SUCCESS,
  REGION_PROFILE_UPDATE_REGION_FAILED,
  REGION_DEPARTMENT_ADD_USER,
  REGION_PROFILE_ADD_DEPARTMENT,
  REGION_PROFILE_INITIAL_STATE,
} from '../actions/types';
import {
  RegionProfileState,
  RegionProfileActionTypes,
} from '../modules/RegionProfile/regionProfile.types';

const INITIAL_STATE: RegionProfileState = {
  responsiblesList: [],
  presidentsList: [],
  vicePresidentsList: [],
  presidentsListFilter: [],
  vicePresidentsListFilter: [],
  departmentsList: [],
  logo: null,
  name: '',
  headOffice: '',
  president: null,
  vicePresident: null,
  responsibles: [],
  departments: [],
  nameError: '',
  headOfficeError: '',
  responsiblesError: '',
  departmentsError: '',
  messageError: '',
  getRegionMessageError: '',
  title: 'Ajouter Région',
  textButton: 'Ajouter',
  loadingPage: true,
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: RegionProfileActionTypes
): RegionProfileState => {
  switch (action.type) {
    case REGION_GET_All_LIST_SUCCESS:
      return {
        ...state,
        responsiblesList: action.allResponsiblesList,
        presidentsList: action.allPresidentsList,
        vicePresidentsList: action.allVicePresidentList,
        presidentsListFilter: action.allPresidentsList,
        vicePresidentsListFilter: action.allVicePresidentList,
        departmentsList: action.allDepartmentsList,
      };

    case REGION_GET_All_LIST_FAILED:
      return { ...state, messageError: action.payload };

    case REGION_PROFILE_FILTER_USERS_LIST_CHANGED: {
      let newUsersList: Array<any> = Object.assign([], action.payload);

      if (action.role === 'president') {
        return {
          ...state,
          vicePresidentsList: newUsersList.filter(
            (item) => item._id !== action.userSelect._id
          ),
        };
      } else {
        return {
          ...state,
          presidentsList: newUsersList.filter(
            (item) => item._id !== action.userSelect._id
          ),
        };
      }
    }

    case REGION_PROFILE_GET_REGION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        title: 'Modifier Région',
        textButton: 'Modifier',
        loadingPage: false,
      };

    case REGION_PROFILE_GET_REGION_FAILED:
      return {
        ...state,
        getRegionMessageError: action.payload,
        loadingPage: false,
      };

    case REGION_PROFILE_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case REGION_PROFILE_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case REGION_PROFILE_CREATE_REGION_SUCCESS:
      return { ...state, loading: false };

    case REGION_PROFILE_CREATE_REGION_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case REGION_PROFILE_UPDATE_REGION_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case REGION_PROFILE_UPDATE_REGION_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case REGION_DEPARTMENT_ADD_USER: {
      if (action.userType === 'responsible') {
        return {
          ...state,
          responsiblesList: [...[action.payload], ...state.responsiblesList],
        };
      } else if (action.userType === 'president') {
        return {
          ...state,
          presidentsList: [...[action.payload], ...state.presidentsList],
          presidentsListFilter: [
            ...[action.payload],
            ...state.presidentsListFilter,
          ],
        };
      } else {
        return {
          ...state,
          vicePresidentsList: [
            ...[action.payload],
            ...state.vicePresidentsList,
          ],
          vicePresidentsListFilter: [
            ...[action.payload],
            ...state.vicePresidentsListFilter,
          ],
        };
      }
    }

    case REGION_PROFILE_ADD_DEPARTMENT:
      return {
        ...state,
        departmentsList: [...[action.payload], ...state.departmentsList],
      };

    case REGION_PROFILE_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

import axios from 'axios';
import { URL } from './configApi';
import {
  ADD_USER_PROPS_CHANGED,
  ADD_USER_PROPS_VALIDATION,
  REGION_DEPARTMENT_ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  AddUserState,
  AddUserPropsChangedAction,
  AddUserInitialStateAction,
} from '../modules/AddUser/addUser.types';
import { GetUserApiResponse } from './typesApi';
//Utils From UserProfile
import {
  validateFieldsUserProfile,
  userProfileCreateUserObject,
} from '../modules/UserProfile/userProfile.utils';

export const addUserPropsChanged = (
  prop: string,
  value: any
): AddUserPropsChangedAction => {
  return { type: ADD_USER_PROPS_CHANGED, prop, value };
};

export const addUserConfirm = (
  userType: string,
  props: AddUserState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsUserProfile(props);
  dispatch({ type: ADD_USER_PROPS_VALIDATION, payload: newProps });
  const profile = userProfileCreateUserObject(newProps);

  if (valid && profile) {
    dispatch({
      type: ADD_USER_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    axios
      .post(`${URL}/users`, profile)
      .then(({ data }: { data: GetUserApiResponse }) => {
        if (data.status === 'success') {
          //Dispatch to regionReducer && departmentReducer && AddDepartmentReducer
          dispatch({
            type: REGION_DEPARTMENT_ADD_USER,
            userType,
            payload: {
              _id: data.response.data._id,
              name: `${data.response.data.firstName} ${data.response.data.firstName}`,
            },
          });
          dispatch({
            type: ADD_USER_PROPS_CHANGED,
            prop: 'loading',
            value: false,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: ADD_USER_FAILED,
            payload: 'Utilisateur existe déjà.',
          });
        }
      });
  }
};

export const addUserInitialState = (): AddUserInitialStateAction => {
  return { type: ADD_USER_INITIAL_STATE };
};

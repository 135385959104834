import { css } from 'styled-components';

export const uploadImageStyles = () => {
  return css`
    position: relative;
    .avater {
      width: 120px;
      height: 120px;
      border: 5px solid #ff7e04;
      border-radius: 50%;
    }
    .image-content {
      position: absolute;
      z-index: 1;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(55, 55, 55, 0.15);
      cursor: pointer;
      .label {
        padding: 4px;
      }
    }
  `;
};

import { css } from 'styled-components';

export const statisticsStyles = () => {
  return css`
    display: flex;
    flex-direction: column;
    .main-header {
      display: flex;
      width: 1000px;
      margin-bottom: 25px;
      margin-top: 15px;
    }
  `;
};

import React from 'react';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Style
import styled from 'styled-components';
import { addUserStyles } from './addDepartment.styles';
//Types
import { AddDepartmentFormProps } from './addDepartment.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';
import Tags from '../../components/Tags';
import Modal from '../../components/Modal';
//Modules
import AddUser from '../AddUser';
//Actions
import {
  getListUsers,
  addDepartmentFilterListUsers,
  addDepartmentPropsChanged,
  addDepartmentConfirm,
  addDepartmentInitialState,
} from '../../actions/addDepartmentAction';
//Reducers
import { RootState } from '../../reducers';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';
//Utils

const AddDepartment: React.FC<AddDepartmentFormProps> = (props) => {
  const [openUserModal, setOpenUserModal] = React.useState<boolean>(false);
  const [userType, setUserType] = React.useState<string>('');
  const {
    responsiblesList,
    presidentsList,
    vicePresidentsList,
    presidentsListFilter,
    vicePresidentsListFilter,
    logo,
    name,
    headOffice,
    president,
    vicePresident,
    responsibles,
    nameError,
    headOfficeError,
    responsiblesError,
    messageError,
    loading,
    onSaveChanged,
    snackbarShowMessage,
  } = props;

  React.useEffect(() => {
    props.addDepartmentInitialState();
    props.getListUsers();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.addDepartmentPropsChanged(event.target.name, event.target.value);
  };

  const handleFileOnChange = (event: any) => {
    const file = event.target.files[0];

    props.addDepartmentPropsChanged('logo', file);
  };

  const handleClickSave = () => {
    props.addDepartmentConfirm(props, () => {
      snackbarShowMessage('Département est ajouté avec succès');
      setTimeout(() => {
        onSaveChanged();
      }, 2000);
    });
  };

  return (
    <Wrapper>
      <Modal
        open={openUserModal}
        onClose={() => setOpenUserModal(!openUserModal)}
      >
        <AddUser
          onSaveChanged={() => setOpenUserModal(!openUserModal)}
          userType={userType}
        ></AddUser>
      </Modal>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ alignSelf: 'flex-start' }}
      >
        Ajouter Département
      </Text>
      <div className='main-header'>
        <UploadImage
          id='departmentImage'
          image={logo}
          emptyImage={null}
          onChange={(e) => {
            handleFileOnChange(e);
          }}
        ></UploadImage>
      </div>
      <div className='main-content'>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Input
              value={name}
              type='text'
              name='name'
              placeholder='Nom de Département'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            {nameError && <Text error>{nameError}</Text>}
          </Grid>
          <Grid item xs={6}>
            <Input
              value={headOffice}
              type='text'
              name='headOffice'
              placeholder='Siège Social'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            {/* {headOfficeError && <Text error>{'headOfficeError'}</Text>} */}
          </Grid>
          <Grid item xs={12}>
            <Tags
              multiple
              options={responsiblesList}
              tags={responsibles}
              placeholder='Responsables Département'
              getTags={(responsibles) => {
                props.addDepartmentPropsChanged('responsibles', responsibles);
              }}
              handleClickAdd={() => {
                setOpenUserModal(!openUserModal);
                setUserType('responsible');
              }}
            ></Tags>
            {responsiblesError && <Text error>{responsiblesError}</Text>}
          </Grid>
          <Grid item xs={12}>
            <Tags
              multiple={false}
              options={presidentsList}
              tags={president}
              placeholder='Président Département'
              getTags={(president) => {
                props.addDepartmentPropsChanged('president', president);
                if (president) {
                  props.addDepartmentFilterListUsers(
                    'president',
                    president,
                    vicePresidentsListFilter
                  );
                }
              }}
              handleClickAdd={() => {
                setOpenUserModal(!openUserModal);
                setUserType('president');
              }}
            ></Tags>
          </Grid>
          <Grid item xs={12}>
            <Tags
              multiple={false}
              options={vicePresidentsList}
              tags={vicePresident}
              placeholder='Vice Président Département'
              getTags={(vicePresident) => {
                props.addDepartmentPropsChanged('vicePresident', vicePresident);
                if (vicePresident) {
                  props.addDepartmentFilterListUsers(
                    'vicePresident',
                    vicePresident,
                    presidentsListFilter
                  );
                }
              }}
              handleClickAdd={() => {
                setOpenUserModal(!openUserModal);
                setUserType('vicePresident');
              }}
            ></Tags>
          </Grid>

          <Grid item xs={12} container alignItems='flex-end' justify='flex-end'>
            {messageError && <Text error>{messageError}</Text>}
          </Grid>
        </Grid>

        <div className='main-footer'>
          <Button onClick={handleClickSave} width={180} margin={10}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Ajouter'
            )}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => addUserStyles()}
`;

const mapStateToProps = ({ addDepartment }: RootState) => {
  return {
    responsiblesList: addDepartment.responsiblesList,
    presidentsList: addDepartment.presidentsList,
    vicePresidentsList: addDepartment.vicePresidentsList,
    presidentsListFilter: addDepartment.presidentsListFilter,
    vicePresidentsListFilter: addDepartment.vicePresidentsListFilter,

    logo: addDepartment.logo,
    name: addDepartment.name,
    headOffice: addDepartment.headOffice,
    president: addDepartment.president,
    vicePresident: addDepartment.vicePresident,
    responsibles: addDepartment.responsibles,
    nameError: addDepartment.nameError,
    headOfficeError: addDepartment.headOfficeError,
    responsiblesError: addDepartment.responsiblesError,
    messageError: addDepartment.messageError,
    loading: addDepartment.loading,
  };
};

export const connector = connect(mapStateToProps, {
  getListUsers,
  addDepartmentFilterListUsers,
  addDepartmentPropsChanged,
  addDepartmentConfirm,
  addDepartmentInitialState,
});

export default connector(withSnackbar(AddDepartment));

import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Styles
import styled from 'styled-components';
import { userProfileStyles, useStyles } from './userProfile.styles';
//Types
import { UserProfileFormProps } from './userProfile.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';
//Modules

//Assets/Images
import camera from '../../assets/images/camera.png';
//Actions
import {
  userProfileGetUser,
  userProfilePropsChanged,
  userProfileCreateUser,
  userProfileUpdateUser,
  userProfileIntialState,
} from '../../actions/userProfileActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';
import { withSnackbar } from '../../hoc/snackBarHoc';

const permissions = [
  {
    id: 'read/write',
    label: 'Lire et Ecrire',
  },
  {
    id: 'read',
    label: 'Lecture seulement',
  },
  // {
  //   id: 'speakers',
  //   label: 'Collaborators',
  // },
];

const UserProfile: React.FC<any> = (props) => {
  const classes = useStyles();
  const {
    firstName,
    lastName,
    email,
    address,
    country,
    codePostal,
    phoneNumber,
    mobile,
    photo,
    permission,
    firstNameError,
    lastNameError,
    emailError,
    permissionError,
    getUserMessageError,
    messageError,
    title,
    textButton,
    loading,
    loadingPage,
  } = props;

  const userId: string = props.match.params.userId;
  const disabledField = userId ? true : false;

  React.useEffect(() => {
    if (userId) {
      props.userProfileGetUser(userId);
    }
    props.userProfileIntialState();
    /* eslint-disable-next-line */
  }, []);

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.userProfilePropsChanged('permission', event.target.value);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    props.userProfilePropsChanged('photo', file);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.userProfilePropsChanged(event.target.name, event.target.value);
  };

  const handleClickSave = () => {
    if (userId) {
      props.userProfileUpdateUser(userId, props, () => {
        props.snackbarShowMessage("L'utilisateur est modifié avec succès");
        setTimeout(() => {
          props.history.push('/users');
        }, 2000);
      });
    } else {
      props.userProfileCreateUser(props, () => {
        props.snackbarShowMessage("L'utilisateur est ajouté avec succès");
        setTimeout(() => {
          props.history.push('/users');
        }, 2000);
      });
    }
  };

  const handleClickCancel = () => {
    props.history.goBack();
  };

  if (userId && loadingPage) {
    return (
      <Wrapper>
        <div className='card'>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Wrapper>
    );
  } else if (!loadingPage && getUserMessageError) {
    return (
      <Wrapper>
        <div className='card'>
          <Text error fontWeight={600} fontSize={16} lineHeight={24}>
            {getUserMessageError}
          </Text>
        </div>
      </Wrapper>
    );
  }

  let emptyImage =
    userId &&
    `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

  return (
    <Wrapper>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ width: '1000px' }}
      >
        {title}
      </Text>
      <div className='card'>
        <div className='main-header'>
          <UploadImage
            image={photo}
            emptyImage={emptyImage}
            onChange={(e) => {
              handleFileChange(e);
            }}
          ></UploadImage>
        </div>
        <div className='main-content'>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Input
                  value={firstName}
                  type='text'
                  name='firstName'
                  placeholder='Nom'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
                {firstNameError && <Text error>{firstNameError}</Text>}
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={lastName}
                  type='text'
                  name='lastName'
                  placeholder='Prénom'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
                {lastNameError && <Text error>{lastNameError}</Text>}
              </Grid>
              <Grid item xs={12}>
                <Input
                  disabled={disabledField}
                  value={email}
                  type='text'
                  name='email'
                  placeholder='Email'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
                {emailError && <Text error>{emailError}</Text>}
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={address}
                  type='text'
                  name='address'
                  placeholder='Adresse'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={country}
                  type='text'
                  name='country'
                  placeholder='Ville'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  value={codePostal}
                  type='text'
                  name='codePostal'
                  placeholder='Code postal'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={phoneNumber}
                  type='text'
                  name='phoneNumber'
                  placeholder='Numéro de téléphone'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={mobile}
                  type='text'
                  name='mobile'
                  placeholder='Mobile'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginTop: '10px' }}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='permission'>Permission</InputLabel>
                    <Select
                      disabled={disabledField}
                      value={permission}
                      name='permission'
                      onChange={handleSelectChange}
                      label='Permission'
                      id='permission'
                      // inputProps={{ className: 'text-field-select' }}
                    >
                      {permissions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <Text color='#373737'>{option.label}</Text>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {permissionError && <Text error>{permissionError}</Text>}
              </Grid>
              <Grid item xs={6} container alignItems='center' justify='center'>
                {messageError && <Text error>{messageError}</Text>}
              </Grid>
            </Grid>
          </div>
          <div className='main-footer'>
            <Button onClick={handleClickCancel} width={180} margin={10}>
              Annuler
            </Button>
            <Button onClick={handleClickSave} width={180} margin={10}>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                textButton
              )}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => userProfileStyles()}
`;

const mapStateToProps = ({ userProfile }: RootState) => {
  return {
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    email: userProfile.email,
    address: userProfile.address,
    country: userProfile.country,
    codePostal: userProfile.codePostal,
    phoneNumber: userProfile.phoneNumber,
    mobile: userProfile.mobile,
    photo: userProfile.photo,
    permission: userProfile.permission,
    firstNameError: userProfile.firstNameError,
    lastNameError: userProfile.lastNameError,
    emailError: userProfile.emailError,
    permissionError: userProfile.permissionError,
    getUserMessageError: userProfile.getUserMessageError,
    messageError: userProfile.messageError,
    title: userProfile.title,
    textButton: userProfile.textButton,
    loading: userProfile.loading,
    loadingPage: userProfile.loadingPage,
  };
};

export const connector = connect(mapStateToProps, {
  userProfileGetUser,
  userProfilePropsChanged,
  userProfileCreateUser,
  userProfileUpdateUser,
  userProfileIntialState,
});

export default connector(withSnackbar(UserProfile));

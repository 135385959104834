import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
//Material

//Styles

import styled from 'styled-components';
import { usersStyles } from './users.styles';
//Types
import { UsersListProps } from './users.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import EmptyData from '../../components/EmptyData';
import Table from '../../components/Tables/UsersTable';
import Dialog from '../../components/Dialog';
//Modules

//Assets/Images
import emptyState from '../../assets/images/emptyState.svg';
//Actions
import {
  usersGetAllUsers,
  usersPropsChanged,
  usersGetFilterUsers,
  usersDeleteUser,
  usersInitialState,
} from '../../actions/usersActions';
//Reducers
import { RootState } from '../../reducers';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';
//Utils

const Users: React.FC<any> = (props) => {
  const { users, usersFilter, search, messageError, loading } = props;
  const [page, setPage] = React.useState(0);
  const [limit, setlimitPage] = React.useState(10);
  const [userId, setuserId] = React.useState('');
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // props.usersGetAllUsers(page, limit);
    props.usersGetAllUsers();
    /* eslint-disable-next-line */
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setlimitPage(+event.target.value);
    setPage(0);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.usersGetFilterUsers(event.target.value, usersFilter);
    // props.usersPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSearch = () => {
    props.usersGetFilterUsers(search, usersFilter);
  };

  const handleClickDelete = () => {
    props.usersDeleteUser(userId, users, () => {
      props.snackbarShowMessage("L'utilisateur est supprimé avec succès");
      setOpen(false);
    });
  };

  const handleClickOpenDialog = (userId: string) => {
    setOpen(true);
    setuserId(userId);
  };

  const handleClickCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Wrapper>
      <div className='main-header'>
        <div className='div-input-search'>
          <Input
            value={search}
            type='text'
            name='search'
            placeholder='Rechercher'
            width={800}
            margin='10px 0'
            className='input-search'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEventChange(e)
            }
          />
          <Button
            onClick={handleClickSearch}
            width={120}
            background='#373737'
            className='button-search'
          >
            Rechercher
          </Button>
        </div>

        <Button
          onClick={() => props.history.push('/users/add-user')}
          width={150}
          margin={10}
        >
          Ajouter Utilisteur
        </Button>
      </div>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ width: '1000px', marginTop: '30px' }}
      >
        Liste des Utilisateurs
      </Text>
      <div className='card'>
        <Dialog
          open={open}
          subject='Supprimer Utilisateur'
          content='Êtes-vous sûr de vouloir supprimer cet utilisateur?'
          agreeText='Supprimer'
          handleAgree={handleClickDelete}
          handleClose={handleClickCloseDialog}
        ></Dialog>
        <EmptyData
          data={users}
          loading={loading}
          message='La liste des clients est vide'
          messageError={messageError}
          image={emptyState}
        >
          <Table
            data={users}
            count={users.length}
            rowsPerPage={limit}
            page={page}
            changePage={handleChangePage}
            changeRowsPerPage={handleChangeRowsPerPage}
            clickDelete={(userId) => {
              handleClickOpenDialog(userId);
            }}
          ></Table>
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => usersStyles()}
`;

const mapStateToProps = ({ users }: RootState) => {
  return {
    users: users.users,
    usersFilter: users.usersFilter,
    search: users.search,
    messageError: users.messageError,
    loading: users.loading,
  };
};

export const connector = connect(mapStateToProps, {
  usersGetAllUsers,
  usersPropsChanged,
  usersGetFilterUsers,
  usersDeleteUser,
  usersInitialState,
});

export default connector(withSnackbar(Users));

import { isEmpty, isEqual } from '../../utils/validations';
import { UpdatePasswordState } from './updatePassword.types';

export const validateFieldsPUpdatePassword = (state: UpdatePasswordState) => {
  let newProps: UpdatePasswordState = Object.assign({}, state);

  if (isEmpty(newProps.passwordCurrent)) {
    newProps.passwordCurrentError = 'Ancien mot de passe est obligatoire.';
  } else {
    newProps.passwordCurrentError = '';
  }

  if (isEmpty(newProps.newPassword)) {
    newProps.newPasswordError = 'Nouveau mot de passe est obligatoire.';
  } else if (newProps.newPassword.length < 4) {
    newProps.newPasswordError =
      'Le mot de passe doit contenir au moins 4 caractères.';
  } else {
    newProps.newPasswordError = '';
  }

  if (isEmpty(newProps.passwordConfirm)) {
    newProps.passwordConfirmError = 'Confirm mot de passe est obligatoire.';
  } else if (!isEqual(newProps.newPassword, newProps.passwordConfirm)) {
    newProps.passwordConfirmError =
      'La confirmation ne correspond pas au nouveau mot de passe.';
  } else {
    newProps.passwordConfirmError = '';
  }

  let valid = false;
  if (
    newProps.passwordCurrentError === '' &&
    newProps.newPasswordError === '' &&
    newProps.passwordConfirmError === ''
  ) {
    valid = true;
  }
  return { newProps, valid };
};

import {
  USERS_PROPS_CHANGED,
  USERS_GET_LIST_SUCCESS,
  USERS_GET_LIST_FAILED,
  USERS_GET_LIST_FILTER_USERS,
  USERS_DELETE_USER_FAILED,
  USERS_DELETE_USER_SUCCESS,
  USERS_INITIAL_STATE,
} from '../actions/types';
import { UsersState, UsersActionTypes } from '../modules/Users/users.types';

const INITIAL_STATE: UsersState = {
  users: [],
  usersFilter: [],
  search: '',
  messageError: '',
  loading: true,
};

export default (
  state = INITIAL_STATE,
  action: UsersActionTypes
): UsersState => {
  switch (action.type) {
    case USERS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case USERS_GET_LIST_SUCCESS:
      return {
        ...state,
        users: action.payload,
        usersFilter: action.payload,
        loading: false,
      };
    case USERS_GET_LIST_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case USERS_GET_LIST_FILTER_USERS: {
      return {
        ...state,
        search: action.search,
        users: action.payload,
        loading: false,
      };
    }

    case USERS_DELETE_USER_SUCCESS:
      return { ...state, users: action.payload, loading: false };
    case USERS_DELETE_USER_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case USERS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

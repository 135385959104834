import React from 'react';
import { connect } from 'react-redux';
//Material
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
//Styles
import styled from 'styled-components';
import { adminProfileStyles, useStyles } from './adminProfile.styles';
//Types
import { AdminProfileFormProps } from './adminProfile.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import SideBar from '../../components/Drawer';
import UploadImage from '../../components/UploadImage';
//Actions
import {
  adminProfileGetCurrentUser,
  adminProfilePropsChanged,
  adminProfileConfirm,
  adminProfileIntialState,
} from '../../actions/adminProfileActions';

//Reducers
import { RootState } from '../../reducers';
//Utils
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from '../../utils/storage';
//Hoc
import { withSnackbar } from '../../hoc/snackBarHoc';

const AdminProfile: React.FC<any> = (props) => {
  const classes = useStyles();

  const admin = getItemFromLocalStorage('user');
  const {
    firstName,
    lastName,
    email,
    address,
    country,
    codePostal,
    phoneNumber,
    mobile,
    photo,
    firstNameError,
    lastNameError,
    emailError,
    messageError,
    loading,
    loadingPage,
  } = props;

  React.useEffect(() => {
    props.adminProfileGetCurrentUser();
    /* eslint-disable-next-line */
  }, []);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    props.adminProfilePropsChanged('photo', file);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.adminProfilePropsChanged(event.target.name, event.target.value);
  };

  const handleClickUpdateProfile = () => {
    props.adminProfileConfirm(props, () =>
      props.snackbarShowMessage('le profil est modifié avec succès')
    );
  };

  if (loadingPage) {
    return (
      <SideBar>
        <Wrapper>
          <div className='card'>
            <CircularProgress className={classes.circularProgress} />
          </div>
        </Wrapper>
      </SideBar>
    );
  }

  return (
    <SideBar>
      <Wrapper>
        <div className='card'>
          {loadingPage ? (
            <CircularProgress className={classes.circularProgress} />
          ) : (
            <React.Fragment>
              <div className='main-header'>
                <UploadImage
                  image={photo}
                  emptyImage={`${admin.firstName
                    .charAt(0)
                    .toUpperCase()}${admin.lastName.charAt(0).toUpperCase()}`}
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                ></UploadImage>

                <Button
                  height={30}
                  margin={10}
                  background='#FF7E04'
                  onClick={() => {
                    console.log('contact support');
                  }}
                >
                  {`${admin.firstName} ${admin.lastName}`}
                </Button>
                {/* <Text
                  fontWeight={600}
                  fontSize={14}
                  lineHeight={21}
                  color='#373737'
                >
                  Super Admin
                </Text> */}
              </div>
              <div className='main-content'>
                <Text
                  fontWeight={600}
                  fontSize={14}
                  lineHeight={21}
                  color='#373737'
                >
                  Informations générals
                </Text>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Input
                        value={firstName}
                        type='text'
                        name='firstName'
                        placeholder='Nom'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                      {firstNameError && <Text error>{firstNameError}</Text>}
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={lastName}
                        type='text'
                        name='lastName'
                        placeholder='Prénom'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                      {lastNameError && <Text error>{lastNameError}</Text>}
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        value={email}
                        type='text'
                        name='email'
                        placeholder='Email'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                      {emailError && <Text error>{emailError}</Text>}
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={address}
                        type='text'
                        name='address'
                        placeholder='Adresse'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        value={country}
                        type='text'
                        name='country'
                        placeholder='Ville'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        value={codePostal}
                        type='text'
                        name='codePostal'
                        placeholder='Code postal'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={phoneNumber}
                        type='text'
                        name='phoneNumber'
                        placeholder='Numéro de téléphone'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={mobile}
                        type='text'
                        name='mobile'
                        placeholder='Mobile'
                        margin='10px 0'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleEventChange(e)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {messageError && <Text error>{messageError}</Text>}
                    </Grid>
                  </Grid>
                </div>
                <div className='main-footer'>
                  <Button
                    onClick={handleClickUpdateProfile}
                    width={180}
                    margin={10}
                  >
                    {loading ? (
                      <CircularProgress size={20} color='inherit' />
                    ) : (
                      'Enregistrer'
                    )}
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => adminProfileStyles()}
`;

const mapStateToProps = ({ adminProfile }: RootState) => {
  return {
    firstName: adminProfile.firstName,
    lastName: adminProfile.lastName,
    email: adminProfile.email,
    address: adminProfile.address,
    country: adminProfile.country,
    codePostal: adminProfile.codePostal,
    phoneNumber: adminProfile.phoneNumber,
    mobile: adminProfile.mobile,
    photo: adminProfile.photo,
    firstNameError: adminProfile.firstNameError,
    lastNameError: adminProfile.lastNameError,
    emailError: adminProfile.emailError,
    messageError: adminProfile.messageError,
    loading: adminProfile.loading,
    loadingPage: adminProfile.loadingPage,
  };
};

export const connector = connect(mapStateToProps, {
  adminProfileGetCurrentUser,
  adminProfilePropsChanged,
  adminProfileConfirm,
  adminProfileIntialState,
});

export default connector(withSnackbar(AdminProfile));

import axios from 'axios';
import { URL } from './configApi';
import {
  REGION_GET_All_LIST_SUCCESS,
  REGION_GET_All_LIST_FAILED,
  REGION_PROFILE_FILTER_USERS_LIST_CHANGED,
  REGION_PROFILE_GET_REGION_SUCCESS,
  REGION_PROFILE_GET_REGION_FAILED,
  REGION_PROFILE_PROPS_CHANGED,
  REGION_PROFILE_PROPS_VALIDATION,
  REGION_PROFILE_CREATE_REGION_SUCCESS,
  REGION_PROFILE_CREATE_REGION_FAILED,
  REGION_PROFILE_UPDATE_REGION_SUCCESS,
  REGION_PROFILE_UPDATE_REGION_FAILED,
  REGION_PROFILE_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  RegionProfileState,
  RegionProfilePropsChangedAction,
  RegionProfileInitialStateAction,
  RegionProfileFilterListUsersChangedAction,
} from '../modules/RegionProfile/regionProfile.types';
import {
  UserApiResponse,
  GetUsersApiResponse,
  DepartmentApiResponse,
  GetRegionApiResponse,
  GetArrayRegionApiResponse,
} from './typesApi';
//Utils
import {
  regionProfileGetRegionObject,
  validateFieldsRegionProfile,
  regionProfileObject,
} from '../modules/RegionProfile/regionProfile.utils';

export const getAllList = (): AppThunk => (dispatch) => {
  const promise1 = axios.get(`${URL}/users?permission[eq]=read`);
  const promise2 = axios.get(`${URL}/users?permission[eq]=read/write`);
  const promise3 = axios.get(`${URL}/region_department/departments`);

  let usersWithPermissionRead: UserApiResponse[] = [];
  let responsibles: UserApiResponse[] = [];
  let departments: DepartmentApiResponse[] = [];

  Promise.all([promise1, promise2, promise3])
    .then((values: any) => {
      usersWithPermissionRead = [
        ...values[0].data.response.data.map((user: UserApiResponse) => ({
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
        })),
      ];
      responsibles = [
        ...values[1].data.response.data.map((user: UserApiResponse) => ({
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
        })),
      ];

      departments = [
        ...values[2].data.response.data.map(
          (department: DepartmentApiResponse) => ({
            _id: department._id,
            name: department.name,
          })
        ),
      ];

      dispatch({
        type: REGION_GET_All_LIST_SUCCESS,
        allResponsiblesList: responsibles,
        allPresidentsList: usersWithPermissionRead,
        allVicePresidentList: usersWithPermissionRead,
        allDepartmentsList: departments,
      });
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: REGION_GET_All_LIST_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const regionProfileFilterUsersList = (
  role: string,
  userSelect: any,
  usersList: Array<any>
): RegionProfileFilterListUsersChangedAction => {
  return {
    type: REGION_PROFILE_FILTER_USERS_LIST_CHANGED,
    role,
    userSelect,
    payload: usersList,
  };
};

export const regionProfileGetRegion = (regionId: string): AppThunk => (
  dispatch
) => {
  axios
    .get(`${URL}/region_department/${regionId}/departments`)
    .then(({ data }: { data: GetArrayRegionApiResponse }) => {
      if (data.status === 'success') {
        const region = regionProfileGetRegionObject(data.response.data[0]);

        dispatch({
          type: REGION_PROFILE_GET_REGION_SUCCESS,
          payload: region,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 404) {
        dispatch({
          type: REGION_PROFILE_GET_REGION_FAILED,
          payload: 'Région introuvable.',
        });
      }
    });
};

export const regionProfilePropsChanged = (
  prop: string,
  value: any
): RegionProfilePropsChangedAction => {
  return { type: REGION_PROFILE_PROPS_CHANGED, prop, value };
};

export const regionProfileCreateRegion = (
  props: RegionProfileState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsRegionProfile(props);
  dispatch({ type: REGION_PROFILE_PROPS_VALIDATION, payload: newProps });
  const region = regionProfileObject(newProps);

  if (valid && region) {
    dispatch({
      type: REGION_PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .post(`${URL}/region_department`, region)
      .then(({ data }: { data: GetRegionApiResponse }) => {
        if (data.status === 'success') {
          dispatch({
            type: REGION_PROFILE_CREATE_REGION_SUCCESS,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: REGION_PROFILE_CREATE_REGION_FAILED,
            payload: 'Région existe déjà.',
          });
        }
      });
  }
};

export const regionProfileUpdateRegion = (
  regionId: string,
  props: RegionProfileState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsRegionProfile(props);
  dispatch({ type: REGION_PROFILE_PROPS_VALIDATION, payload: newProps });
  const region = regionProfileObject(newProps);

  if (valid && region) {
    dispatch({
      type: REGION_PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .put(`${URL}/region_department/${regionId}`, region)
      .then(({ data }: { data: GetRegionApiResponse }) => {
        if (data.status === 'success') {
          dispatch({
            type: REGION_PROFILE_UPDATE_REGION_SUCCESS,
            payload: data.response.data,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: REGION_PROFILE_UPDATE_REGION_FAILED,
            payload: 'Région introuvable.',
          });
        }
      });
  }
};

export const regionProfileIntialState = (): RegionProfileInitialStateAction => {
  return { type: REGION_PROFILE_INITIAL_STATE };
};

import { css } from 'styled-components';

export const updatePasswordStyles = () => {
  return css`
    display: flex;
    justify-content: center;
    height: 100vh;
    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 640px;
      width: 1000px;
      margin: 20px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
      border-radius: 20px;
      .main-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 30px;
        .text-error {
          margin: 8px 12px;
        }
      }
    }
  `;
};

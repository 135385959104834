import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { css } from 'styled-components';

const drawerWidth = 250;
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      height: '100vh',
    },
    appBar: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fafcff',
      // borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
      width: `calc(100% - ${drawerWidth}px)`,
      // zIndex: theme.zIndex.drawer + 1,
      // transition: theme.transitions.create(['width', 'margin'], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    menuButton: {
      marginRight: 8,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      backgroundColor: '#ffffff',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      backgroundColor: '#ffffff',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      // overflow: 'hidden',
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    listItemIcon: {
      color: '#000',
    },
    listItemText: {
      fontSize: '16px',
      color: '#000',
    },

    logoContainer: {
      height: '60px',
      marginTop: '15px',
    },

    logoSymbole: {
      height: '100%',
      objectFit: 'contain',
    },
    divider: {
      border: '0.5px solid #F0F1F2',
      margin: '5px 15px',
    },
    circleIcon: {
      cursor: 'pointer',
      color: '#fff',
    },
    headerSettings: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    footer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: '60px',
    },
    profileContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '15px 0',
    },
    imageContent: {
      position: 'relative',
    },

    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      border: '5px solid #FF7E04',
      borderRadius: '50%',
    },
    polygonContent: {
      position: 'absolute',
      zIndex: 1,
      right: 0,
      bottom: 0,
      padding: '5px',
      borderRadius: '50%',
      background: '#fff',
      boxShadow: '0px 0px 20px rgba(55, 55, 55, 0.15)',
      cursor: 'pointer',
    },

    polygon: {
      width: 0,
      height: 0,
      borderLeft: '5px solid transparent',
      borderRight: ' 5px solid transparent',
      borderTop: '10px solid  #DE0682',
    },
  })
);

import {
  ADMIN_PROFILE_PROPS_CHANGED,
  ADMIN_PROFILE_PROPS_VALIDATION,
  GET_CURRENT_USER_FAILED,
  GET_CURRENT_USER_SUCCES,
  ADMIN_PROFILE_SUCCESS,
  ADMIN_PROFILE_FAILED,
  ADMIN_PROFILE_INITIAL_STATE,
} from '../actions/types';
import {
  AdminProfileState,
  AdminProfileActionTypes,
} from '../modules/AdminProfile/adminProfile.types';

const INITIAL_STATE: AdminProfileState = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  country: '',
  codePostal: '',
  phoneNumber: '',
  mobile: '',
  photo: null,
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  messageError: '',
  loadingPage: true,
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: AdminProfileActionTypes
): AdminProfileState => {
  switch (action.type) {
    case ADMIN_PROFILE_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case ADMIN_PROFILE_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case GET_CURRENT_USER_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loadingPage: false,
      };

    case GET_CURRENT_USER_SUCCES:
      return { ...state, ...action.payload, loadingPage: false };

    case ADMIN_PROFILE_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
        loadingPage: false,
      };

    case ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        loadingPage: false,
      };

    case ADMIN_PROFILE_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

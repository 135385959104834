import { SignalCellularNullSharp } from '@material-ui/icons';
import {
  DEPARTMENT_GET_All_LIST_SUCCESS,
  DEPARTMENT_GET_All_LIST_FAILED,
  DEPARTMENT_PROFILE_FILTER_USERS_LIST_CHANGED,
  DEPARTMENT_PROFILE_GET_DEPARTMENT_SUCCESS,
  DEPARTMENT_PROFILE_GET_DEPARTMENT_FAILED,
  DEPARTMENT_PROFILE_PROPS_CHANGED,
  DEPARTMENT_PROFILE_PROPS_VALIDATION,
  DEPARTMENT_PROFILE_CREATE_DEPARTMENT_SUCCESS,
  DEPARTMENT_PROFILE_CREATE_DEPARTMENT_FAILED,
  DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_SUCCESS,
  DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_FAILED,
  REGION_DEPARTMENT_ADD_USER,
  DEPARTMENT_PROFILE_INITIAL_STATE,
} from '../actions/types';
import {
  DepartmentProfileState,
  DepartmentProfileActionTypes,
} from '../modules/DepartmentProfile/departmentProfile.types';

const INITIAL_STATE: DepartmentProfileState = {
  regionsList: [],
  responsiblesList: [],
  presidentsList: [],
  vicePresidentsList: [],
  presidentsListFilter: [],
  vicePresidentsListFilter: [],

  logo: null,
  name: '',
  headOffice: '',
  region: null,
  president: null,
  vicePresident: null,
  responsibles: [],

  nameError: '',
  headOfficeError: '',
  responsiblesError: '',
  messageError: '',
  getDepartmentMessageError: '',
  title: 'Ajouter Département',
  textButton: 'Ajouter',
  loadingPage: true,
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: DepartmentProfileActionTypes
): DepartmentProfileState => {
  switch (action.type) {
    case DEPARTMENT_GET_All_LIST_SUCCESS:
      return {
        ...state,
        regionsList: action.allRegionsList,
        responsiblesList: action.allResponsiblesList,
        presidentsList: action.allPresidentsList,
        vicePresidentsList: action.allVicePresidentList,
        presidentsListFilter: action.allPresidentsList,
        vicePresidentsListFilter: action.allVicePresidentList,
      };

    case DEPARTMENT_GET_All_LIST_FAILED:
      return { ...state, messageError: action.payload };

    case DEPARTMENT_PROFILE_FILTER_USERS_LIST_CHANGED: {
      let newUsersList: Array<any> = Object.assign([], action.payload);

      if (action.role === 'president') {
        return {
          ...state,
          vicePresidentsList: newUsersList.filter(
            (item) => item._id !== action.userSelect._id
          ),
        };
      } else {
        return {
          ...state,
          presidentsList: newUsersList.filter(
            (item) => item._id !== action.userSelect._id
          ),
        };
      }
    }

    case DEPARTMENT_PROFILE_GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        title: 'Modifier Département',
        textButton: 'Modifier',
        loadingPage: false,
      };

    case DEPARTMENT_PROFILE_GET_DEPARTMENT_FAILED:
      return {
        ...state,
        getDepartmentMessageError: action.payload,
        loadingPage: false,
      };

    case DEPARTMENT_PROFILE_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case DEPARTMENT_PROFILE_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case DEPARTMENT_PROFILE_CREATE_DEPARTMENT_SUCCESS:
      return { ...state, loading: false };

    case DEPARTMENT_PROFILE_CREATE_DEPARTMENT_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case REGION_DEPARTMENT_ADD_USER: {
      if (action.userType === 'responsible') {
        return {
          ...state,
          responsiblesList: [...[action.payload], ...state.responsiblesList],
        };
      } else if (action.userType === 'president') {
        return {
          ...state,
          presidentsList: [...[action.payload], ...state.presidentsList],
          presidentsListFilter: [
            ...[action.payload],
            ...state.presidentsListFilter,
          ],
        };
      } else {
        return {
          ...state,
          vicePresidentsList: [
            ...[action.payload],
            ...state.vicePresidentsList,
          ],
          vicePresidentsListFilter: [
            ...[action.payload],
            ...state.vicePresidentsListFilter,
          ],
        };
      }
    }

    case DEPARTMENT_PROFILE_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

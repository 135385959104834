import {
  ADD_USER_PROPS_CHANGED,
  ADD_USER_PROPS_VALIDATION,
  ADD_USER_FAILED,
  ADD_USER_INITIAL_STATE,
} from '../actions/types';
import {
  AddUserState,
  AddUserActionTypes,
} from '../modules/AddUser/addUser.types';

const INITIAL_STATE: AddUserState = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  country: '',
  codePostal: '',
  phoneNumber: '',
  mobile: '',
  photo: null,
  permission: 'read',
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  permissionError: '',
  messageError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: AddUserActionTypes
): AddUserState => {
  switch (action.type) {
    case ADD_USER_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case ADD_USER_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case ADD_USER_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case ADD_USER_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

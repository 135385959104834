import React from 'react';
//Styles
import styled from 'styled-components';
import { uploadImageStyles } from './uploadImage.styles';
//Material
import Avatar from '@material-ui/core/Avatar';

//Icons
import { Icon } from 'react-icons-kit';
import { ic_edit } from 'react-icons-kit/md/ic_edit';
//ACTIONS
import { URL_IMAGE } from '../../actions/configApi';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';

export type UploadImageProps = {
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  image?: string | object | null;
  emptyImage?: string | null;
};

const UploadImage: React.FC<UploadImageProps> = (props) => {
  const { id, onChange, image, emptyImage } = props;

  const onClear = (event: any) => {
    event.target.value = null;
  };

  let photo = null;
  if (typeof image === 'string') {
    photo = `${URL_IMAGE}/${image}`;
  } else if (image) {
    photo = URL.createObjectURL(image);
  }

  return (
    <Wrapper>
      <div className='image-content'>
        <input
          id={id || 'fileUpload'}
          type='file'
          hidden
          accept='image/gif, image/jpeg, image/png'
          onChange={onChange}
          onClick={(e) => onClear(e)}
          //   className='input'
        />
        <label htmlFor={id || 'fileUpload'} className='label'>
          <Icon icon={ic_edit} size='15' style={{ color: '#FF7E04' }} />
        </label>
      </div>
      {photo ? (
        <Avatar alt='Avatar' src={photo} className='avater' />
      ) : (
        <Avatar className='avater'>{emptyImage}</Avatar>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => uploadImageStyles()}
`;

export default React.memo(UploadImage);

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_control_point } from 'react-icons-kit/md/ic_control_point';
//Styles
import styled from 'styled-components';
import { regionProfileStyles, useStyles } from './regionProfile.styles';
//Types
import { RegionProfileFormProps } from './regionProfile.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';
import Tags from '../../components/Tags';
import Modal from '../../components/Modal';

//Modules
import AddUser from '../AddUser';
import AddDepartment from '../AddDepartment';
//Assets/Images
import camera from '../../assets/images/camera.png';
//Actions
import {
  getAllList,
  regionProfileFilterUsersList,
  regionProfileGetRegion,
  regionProfilePropsChanged,
  regionProfileCreateRegion,
  regionProfileUpdateRegion,
  regionProfileIntialState,
} from '../../actions/regionProfileActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';
//hoc
import { withSnackbar } from '../../hoc/snackBarHoc';

const RegionProfile: React.FC<any> = (props) => {
  const [openUserModal, setOpenUserModal] = React.useState<boolean>(false);
  const [openDepartmentModal, setOpenDepartmentModal] = React.useState<boolean>(
    false
  );
  const [userType, setUserType] = React.useState<string>('');
  const classes = useStyles();
  const {
    responsiblesList,
    presidentsList,
    vicePresidentsList,
    presidentsListFilter,
    vicePresidentsListFilter,
    departmentsList,
    logo,
    name,
    headOffice,
    responsibles,
    president,
    vicePresident,
    departments,
    nameError,
    headOfficeError,
    responsiblesError,
    departmentsError,
    getRegionMessageError,
    messageError,
    title,
    textButton,
    loading,
    loadingPage,
  } = props;

  const regionId: string = props.match.params.regionId;

  React.useEffect(() => {
    props.regionProfileIntialState();
    props.getAllList();
    if (regionId) {
      props.regionProfileGetRegion(regionId);
    }

    /* eslint-disable-next-line */
  }, []);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    props.regionProfilePropsChanged('logo', file);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.regionProfilePropsChanged(event.target.name, event.target.value);
  };

  const handleClickSave = () => {
    if (regionId) {
      props.regionProfileUpdateRegion(regionId, props, () => {
        props.snackbarShowMessage('La région est modifiée avec succès');
        setTimeout(() => {
          props.history.push('/regions-departments');
        }, 2000);
      });
    } else {
      props.regionProfileCreateRegion(props, () => {
        props.snackbarShowMessage('La région est ajoutée avec succès');
        setTimeout(() => {
          props.history.push('/regions-departments');
        }, 2000);
      });
    }
  };

  const handleClickCancel = () => {
    props.history.goBack();
  };

  if (regionId && loadingPage) {
    return (
      <Wrapper>
        <div className='card'>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Wrapper>
    );
  } else if (!loadingPage && getRegionMessageError) {
    return (
      <Wrapper>
        <div className='card'>
          <Text error fontWeight={600} fontSize={16} lineHeight={24}>
            {getRegionMessageError}
          </Text>
        </div>
      </Wrapper>
    );
  }

  let emptyImage = regionId && name.charAt(0).toUpperCase();

  return (
    <React.Fragment>
      <Modal
        open={openUserModal}
        onClose={() => setOpenUserModal(!openUserModal)}
      >
        <AddUser
          onSaveChanged={() => setOpenUserModal(!openUserModal)}
          userType={userType}
        ></AddUser>
      </Modal>
      <Modal
        open={openDepartmentModal}
        onClose={() => setOpenDepartmentModal(!openDepartmentModal)}
      >
        <AddDepartment
          onSaveChanged={() => setOpenDepartmentModal(!openDepartmentModal)}
        ></AddDepartment>
      </Modal>
      <Wrapper>
        <Text
          fontWeight={600}
          fontSize={16}
          lineHeight={24}
          style={{ width: '1000px' }}
        >
          {title}
        </Text>
        <div className='card'>
          <div className='main-header'>
            <UploadImage
              image={logo}
              emptyImage={emptyImage}
              onChange={(e) => {
                handleFileChange(e);
              }}
            ></UploadImage>
          </div>
          <div className='main-content'>
            <div className={classes.root}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Input
                    value={name}
                    type='text'
                    name='name'
                    placeholder='Nom de région'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                  {nameError && <Text error>{nameError}</Text>}
                </Grid>
                <Grid item xs={6}>
                  <Input
                    value={headOffice}
                    type='text'
                    name='headOffice'
                    placeholder='Siège Social'
                    margin='10px 0'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleEventChange(e)
                    }
                  />
                  {headOfficeError && <Text error>{headOfficeError}</Text>}
                </Grid>
                <Grid item xs={12}>
                  <Tags
                    multiple
                    options={responsiblesList}
                    tags={responsibles}
                    placeholder='Responsables Région'
                    getTags={(responsibles) => {
                      props.regionProfilePropsChanged(
                        'responsibles',
                        responsibles
                      );
                    }}
                    handleClickAdd={() => {
                      setOpenUserModal(!openUserModal);
                      setUserType('responsible');
                    }}
                  ></Tags>
                  {responsiblesError && <Text error>{responsiblesError}</Text>}
                </Grid>
                <Grid item xs={12}>
                  <Tags
                    multiple={false}
                    options={presidentsList}
                    tags={president}
                    placeholder='Président Région'
                    getTags={(president) => {
                      props.regionProfilePropsChanged('president', president);
                      if (president) {
                        props.regionProfileFilterUsersList(
                          'president',
                          president,
                          vicePresidentsListFilter
                        );
                      }
                    }}
                    handleClickAdd={() => {
                      setOpenUserModal(!openUserModal);
                      setUserType('president');
                    }}
                  ></Tags>
                </Grid>
                <Grid item xs={12}>
                  <Tags
                    multiple={false}
                    options={vicePresidentsList}
                    tags={vicePresident}
                    placeholder='Vice Président Région'
                    getTags={(vicePresident) => {
                      props.regionProfilePropsChanged(
                        'vicePresident',
                        vicePresident
                      );
                      if (vicePresident) {
                        props.regionProfileFilterUsersList(
                          'vicePresident',
                          vicePresident,
                          presidentsListFilter
                        );
                      }
                    }}
                    handleClickAdd={() => {
                      setOpenUserModal(!openUserModal);
                      setUserType('vicePresident');
                    }}
                  ></Tags>
                </Grid>
                <Grid item xs={12}>
                  <Tags
                    multiple
                    options={departmentsList}
                    tags={departments}
                    placeholder='Départements'
                    getTags={(departments) => {
                      props.regionProfilePropsChanged(
                        'departments',
                        departments
                      );
                    }}
                    handleClickAdd={() => {
                      setOpenDepartmentModal(!openDepartmentModal);
                    }}
                  ></Tags>
                  {departmentsError && <Text error>{departmentsError}</Text>}
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems='flex-end'
                  justify='flex-end'
                >
                  {messageError && <Text error>{messageError}</Text>}
                </Grid>
              </Grid>
            </div>
            <div className='main-footer'>
              <Button onClick={handleClickCancel} width={180} margin={10}>
                Annuler
              </Button>
              <Button onClick={handleClickSave} width={180} margin={10}>
                {loading ? (
                  <CircularProgress size={20} color='inherit' />
                ) : (
                  textButton
                )}
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

const Wrapper = styled('div')`
  ${() => regionProfileStyles()}
`;

const mapStateToProps = ({ regionProfile }: RootState) => {
  return {
    responsiblesList: regionProfile.responsiblesList,
    presidentsList: regionProfile.presidentsList,
    vicePresidentsList: regionProfile.vicePresidentsList,
    presidentsListFilter: regionProfile.presidentsListFilter,
    vicePresidentsListFilter: regionProfile.vicePresidentsListFilter,
    departmentsList: regionProfile.departmentsList,

    logo: regionProfile.logo,
    name: regionProfile.name,
    headOffice: regionProfile.headOffice,
    president: regionProfile.president,
    vicePresident: regionProfile.vicePresident,
    responsibles: regionProfile.responsibles,
    departments: regionProfile.departments,
    nameError: regionProfile.nameError,
    headOfficeError: regionProfile.headOfficeError,
    responsiblesError: regionProfile.responsiblesError,
    departmentsError: regionProfile.departmentsError,
    messageError: regionProfile.messageError,
    getRegionMessageError: regionProfile.getRegionMessageError,
    title: regionProfile.title,
    textButton: regionProfile.textButton,
    loadingPage: regionProfile.loadingPage,
    loading: regionProfile.loading,
  };
};

export const connector = connect(mapStateToProps, {
  getAllList,
  regionProfileFilterUsersList,
  regionProfileGetRegion,
  regionProfilePropsChanged,
  regionProfileCreateRegion,
  regionProfileUpdateRegion,
  regionProfileIntialState,
});

export default connector(withSnackbar(RegionProfile));

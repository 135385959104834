import axios from 'axios';
import { URL } from './configApi';
import {
  DEPARTMENT_GET_All_LIST_SUCCESS,
  DEPARTMENT_GET_All_LIST_FAILED,
  DEPARTMENT_PROFILE_FILTER_USERS_LIST_CHANGED,
  DEPARTMENT_PROFILE_GET_DEPARTMENT_SUCCESS,
  DEPARTMENT_PROFILE_GET_DEPARTMENT_FAILED,
  DEPARTMENT_PROFILE_PROPS_CHANGED,
  DEPARTMENT_PROFILE_PROPS_VALIDATION,
  DEPARTMENT_PROFILE_CREATE_DEPARTMENT_SUCCESS,
  DEPARTMENT_PROFILE_CREATE_DEPARTMENT_FAILED,
  DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_SUCCESS,
  DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_FAILED,
  DEPARTMENT_PROFILE_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  DepartmentProfileState,
  DepartmentProfilePropsChangedAction,
  DepartmentProfileInitialStateAction,
  DepartmentProfileFilterListUsersChangedAction,
} from '../modules/DepartmentProfile/departmentProfile.types';
import {
  UserApiResponse,
  GetUsersApiResponse,
  DepartmentApiResponse,
  GetRegionApiResponse,
  GetArrayDepartmentApiResponse,
  RegionApiResponse,
  GetDepartmentApiResponse,
} from './typesApi';
//Utils
import {
  departmentProfileGetDepartmentObject,
  validateFieldsDepartmentProfile,
  departmentProfileObject,
} from '../modules/DepartmentProfile/departmentProfile.utils';

export const departmentProfileGetAllList = (): AppThunk => (dispatch) => {
  const promise1 = axios.get(`${URL}/users?permission[eq]=read`);
  const promise2 = axios.get(`${URL}/users?permission[eq]=read/write`);
  const promise3 = axios.get(`${URL}/region_department/regions`);

  let usersWithPermissionRead: UserApiResponse[] = [];
  let responsibles: UserApiResponse[] = [];
  let regions: RegionApiResponse[] = [];

  Promise.all([promise1, promise2, promise3])
    .then((values: any) => {
      usersWithPermissionRead = [
        ...values[0].data.response.data.map((user: UserApiResponse) => ({
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
        })),
      ];
      responsibles = [
        ...values[1].data.response.data.map((user: UserApiResponse) => ({
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
        })),
      ];

      regions = [
        ...values[2].data.response.data.map((region: RegionApiResponse) => ({
          _id: region._id,
          name: region.name,
        })),
      ];

      dispatch({
        type: DEPARTMENT_GET_All_LIST_SUCCESS,
        allRegionsList: regions,
        allResponsiblesList: responsibles,
        allPresidentsList: usersWithPermissionRead,
        allVicePresidentList: usersWithPermissionRead,
      });
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: DEPARTMENT_GET_All_LIST_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const departmentProfileFilterUsersList = (
  role: string,
  userSelect: any,
  usersList: Array<any>
): DepartmentProfileFilterListUsersChangedAction => {
  return {
    type: DEPARTMENT_PROFILE_FILTER_USERS_LIST_CHANGED,
    role,
    userSelect,
    payload: usersList,
  };
};

export const departmentProfileGetDepartment = (
  departmentId: string
): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/region_department/${departmentId}`)
    .then(({ data }: { data: GetArrayDepartmentApiResponse }) => {
      if (data.status === 'success') {
        const department = departmentProfileGetDepartmentObject(
          data.response.data[0]
        );

        dispatch({
          type: DEPARTMENT_PROFILE_GET_DEPARTMENT_SUCCESS,
          payload: department,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 404) {
        dispatch({
          type: DEPARTMENT_PROFILE_GET_DEPARTMENT_FAILED,
          payload: 'Département introuvable.',
        });
      }
    });
};

export const departmentProfilePropsChanged = (
  prop: string,
  value: any
): DepartmentProfilePropsChangedAction => {
  return { type: DEPARTMENT_PROFILE_PROPS_CHANGED, prop, value };
};

export const departmentProfileCreateDepartment = (
  props: DepartmentProfileState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsDepartmentProfile(props);
  dispatch({ type: DEPARTMENT_PROFILE_PROPS_VALIDATION, payload: newProps });
  const region = departmentProfileObject(newProps);

  if (valid && region) {
    dispatch({
      type: DEPARTMENT_PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .post(`${URL}/region_department`, region)
      .then(({ data }: { data: GetDepartmentApiResponse }) => {
        if (data.status === 'success') {
          dispatch({
            type: DEPARTMENT_PROFILE_CREATE_DEPARTMENT_SUCCESS,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: DEPARTMENT_PROFILE_CREATE_DEPARTMENT_FAILED,
            payload: 'Département existe déjà.',
          });
        }
      });
  }
};

export const departmentProfileUpdateDepartment = (
  departmentId: string,
  props: DepartmentProfileState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsDepartmentProfile(props);
  dispatch({ type: DEPARTMENT_PROFILE_PROPS_VALIDATION, payload: newProps });
  const department = departmentProfileObject(newProps);

  if (valid && department) {
    dispatch({
      type: DEPARTMENT_PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .put(`${URL}/region_department/${departmentId}`, department)
      .then(({ data }: { data: GetRegionApiResponse }) => {
        if (data.status === 'success') {
          dispatch({
            type: DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_SUCCESS,
            payload: data.response.data,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_FAILED,
            payload: 'Départment introuvable.',
          });
        }
      });
  }
};

export const departmentProfileIntialState = (): DepartmentProfileInitialStateAction => {
  return { type: DEPARTMENT_PROFILE_INITIAL_STATE };
};

import {
  USER_PROFILE_GET_USER_SUCCESS,
  USER_PROFILE_GET_USER_FAILED,
  USER_PROFILE_PROPS_CHANGED,
  USER_PROFILE_PROPS_VALIDATION,
  USER_PROFILE_CREATE_USER_SUCCESS,
  USER_PROFILE_CREATE_USER_FAILED,
  USER_PROFILE_UPDATE_USER_SUCCESS,
  USER_PROFILE_UPDATE_USER_FAILED,
  USER_PROFILE_INITIAL_STATE,
} from '../actions/types';
import {
  UserProfileState,
  UserProfileActionTypes,
} from '../modules/UserProfile/userProfile.types';

const INITIAL_STATE: UserProfileState = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  country: '',
  codePostal: '',
  phoneNumber: '',
  mobile: '',
  photo: null,
  permission: 'read/write',
  firstNameError: '',
  lastNameError: '',
  emailError: '',
  permissionError: '',
  messageError: '',
  getUserMessageError: '',
  title: 'Ajouter Utilisateur',
  textButton: 'Ajouter',
  loadingPage: true,
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: UserProfileActionTypes
): UserProfileState => {
  switch (action.type) {
    case USER_PROFILE_GET_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        title: 'Modifier Utilisateur',
        textButton: 'Modifier',
        loadingPage: false,
      };

    case USER_PROFILE_GET_USER_FAILED:
      return {
        ...state,
        getUserMessageError: action.payload,
        loadingPage: false,
      };

    case USER_PROFILE_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case USER_PROFILE_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case USER_PROFILE_CREATE_USER_SUCCESS:
      return { ...state, loading: false };

    case USER_PROFILE_CREATE_USER_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case USER_PROFILE_UPDATE_USER_SUCCESS:
      return { ...state, ...action.payload, loading: false };

    case USER_PROFILE_UPDATE_USER_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case USER_PROFILE_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

// Libraries Imports
import React, { FC } from 'react';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

import { URL_IMAGE } from '../../actions/configApi';
import Button from '../Button';
// Types
type TreeComponentProps = {
  img?: any;
  data: string[];
  type?: string;
  offline: boolean;
  last?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onOffline: () => void;
};
const Tree: FC<TreeComponentProps> = ({
  img,
  data,
  type,
  last,
  offline,
  onEdit,
  onDelete,
  onOffline,
  children,
}) => {
  // Local variables
  // States
  const [expand, setExpand] = React.useState(false);
  // Queries & Mutaions
  // Functions
  // Local Data
  let color = type === 'region' ? '#FBFAFA' : '#FF67C6';
  let background = type === 'region' ? '#FF67C6' : '#F6F9FD';

  const TreeImageDispalyed = (img: any, data: Array<any>) => {
    if (img === '#') {
      return (
        <Avatar className='avatar'>
          {`${data[0].charAt(0).toUpperCase()}`}
        </Avatar>
      );
    } else {
      return <img src={img} alt='logo' className='region-logo' />;
    }
  };

  return (
    <RowHeader>
      {img ? (
        TreeImageDispalyed(img, data)
      ) : (
        // <img src={img} alt='logo' className='region-logo' />
        <Link last={last || false} />
      )}
      {data.map((dataItem, index) => {
        return (
          <span key={index} onClick={() => setExpand(!expand)}>
            {dataItem}
            {index === 0 && children && (
              <svg
                version='1.1'
                id='Layer_1'
                x='0px'
                y='0px'
                viewBox='0 0 20 20'
                className={expand ? 'down' : 'up'}
              >
                <path
                  d='M17.12,6.02H10H2.88c-2.01,0-2.99,2.45-1.54,3.84l7.12,6.78c0.86,0.82,2.21,0.82,3.07,0l7.12-6.78
       C20.11,8.47,19.13,6.02,17.12,6.02z'
                />
              </svg>
            )}
          </span>
        );
      })}
      <Button
        onClick={onEdit}
        width={90}
        color={type === 'region' ? '#FBFAFA' : '#FFA550'}
        border='1px solid #FFA550'
        background={type === 'region' ? '#FFA550' : '#F6F9FD'}
      >
        Modifier
      </Button>
      <Button
        onClick={onDelete}
        width={90}
        color={type === 'region' ? '#FBFAFA' : '#FF67C6'}
        border='1px solid #FF67C6'
        background={type === 'region' ? '#FF67C6' : '#F6F9FD'}
      >
        Supprimer
      </Button>
      <Button
        onClick={onOffline}
        width={90}
        color='#373737'
        border='1px solid #373737'
        background='#F6F9FD'
        // style={{ margin: '10px 0' }}
      >
        {offline ? 'Hors ligne' : 'En ligne'}
      </Button>
      <div className={`tree-container ${expand ? 'expand' : 'collapse'}`}>
        {children}
      </div>
    </RowHeader>
  );
};
type LinkProps = {
  last: boolean;
};
const Link = styled.div<LinkProps>`
  position: relative;
  border-width: 0 0 1px 1px;
  border-style: dashed;
  border-color: #bdbdbd;
  margin: 0 0 16px 28px;
  ::after {
    display: ${(props) => (props.last ? 'none' : 'block')};
    content: '';
    border-left: 1px dashed #bdbdbd;
    position: absolute;
    left: -1px;
    top: 34px;
    width: 1px;
    height: 18px;
  }
`;
const RowHeader = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr 1fr auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 15px;
  .region-logo {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
    background-color: ghostwhite;
  }
  .avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
  }
  .tree {
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 100px 1fr 1fr auto auto auto;
    grid-gap: 20px;
    grid-column: span 6;
  }
  .tree-container {
    grid-column: span 6;
  }
  span {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    margin: auto;
  }
  .expand {
    display: grid;
  }
  .collapse {
    display: none;
  }
  svg.down {
    transform: rotate(180deg);
  }
  svg.up {
    transform: rotate(0deg);
  }
  svg {
    width: 10px;
    fill: #777777;
    margin-left: 8px;
    transform-origin: center;
  }
`;
export default Tree;

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_control_point } from 'react-icons-kit/md/ic_control_point';
//Styles
import styled from 'styled-components';
import { departmentProfileStyles, useStyles } from './departmentProfile.styles';
//Types
import { DepartmentProfileFormProps } from './departmentProfile.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';
import Tags from '../../components/Tags';
import Modal from '../../components/Modal';

//Modules
import AddUser from '../AddUser';
//Assets/Images

//Actions
import {
  departmentProfileGetAllList,
  departmentProfileFilterUsersList,
  departmentProfileGetDepartment,
  departmentProfilePropsChanged,
  departmentProfileCreateDepartment,
  departmentProfileUpdateDepartment,
  departmentProfileIntialState,
} from '../../actions/departmentProfileActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';

const DepartmentProfile: React.FC<any> = (props) => {
  const [openUserModal, setOpenUserModal] = React.useState<boolean>(false);
  const [userType, setUserType] = React.useState<string>('');
  const classes = useStyles();

  const {
    regionsList,
    responsiblesList,
    presidentsList,
    vicePresidentsList,
    presidentsListFilter,
    vicePresidentsListFilter,
    logo,
    name,
    headOffice,
    region,
    responsibles,
    president,
    vicePresident,
    nameError,
    headOfficeError,
    responsiblesError,
    getDepartmentMessageError,
    messageError,
    title,
    textButton,
    loading,
    loadingPage,
  } = props;

  const departmentId: string = props.match.params.departmentId;

  React.useEffect(() => {
    props.departmentProfileIntialState();
    props.departmentProfileGetAllList();
    if (departmentId) {
      props.departmentProfileGetDepartment(departmentId);
    }
    /* eslint-disable-next-line */
  }, []);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    props.departmentProfilePropsChanged('logo', file);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.departmentProfilePropsChanged(event.target.name, event.target.value);
  };

  const handleClickSave = () => {
    if (departmentId) {
      props.departmentProfileUpdateDepartment(departmentId, props, () => {
        props.snackbarShowMessage('Le département est modifié avec succès');
        setTimeout(() => {
          props.history.push('/regions-departments');
        }, 2000);
      });
    } else {
      props.departmentProfileCreateDepartment(props, () => {
        props.snackbarShowMessage('Le département est ajouté avec succès');
        setTimeout(() => {
          props.history.push('/regions-departments');
        }, 2000);
      });
    }
  };

  const handleClickCancel = () => {
    props.history.goBack();
  };

  let emptyImage = departmentId && name.charAt(0).toUpperCase();

  if (departmentId && loadingPage) {
    return (
      <Wrapper>
        <div className='card'>
          <CircularProgress className={classes.circularProgress} />
        </div>
      </Wrapper>
    );
  } else if (!loadingPage && getDepartmentMessageError) {
    return (
      <Wrapper>
        <div className='card'>
          <Text error fontWeight={600} fontSize={16} lineHeight={24}>
            {getDepartmentMessageError}
          </Text>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Modal
        open={openUserModal}
        onClose={() => setOpenUserModal(!openUserModal)}
      >
        <AddUser
          onSaveChanged={() => setOpenUserModal(!openUserModal)}
          userType={userType}
        ></AddUser>
      </Modal>

      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ width: '1000px' }}
      >
        {title}
      </Text>
      <div className='card'>
        <div className='main-header'>
          <UploadImage
            image={logo}
            emptyImage={emptyImage}
            onChange={(e) => {
              handleFileChange(e);
            }}
          ></UploadImage>
        </div>
        <div className='main-content'>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Tags
                  multiple={false}
                  options={regionsList}
                  tags={region}
                  placeholder='Région'
                  getTags={(region) => {
                    props.departmentProfilePropsChanged('region', region);
                  }}
                  hideIconAdd
                ></Tags>
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={name}
                  type='text'
                  name='name'
                  placeholder='Nom de département'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
                {nameError && <Text error>{nameError}</Text>}
              </Grid>
              <Grid item xs={6}>
                <Input
                  value={headOffice}
                  type='text'
                  name='headOffice'
                  placeholder='Siège Social'
                  margin='10px 0'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleEventChange(e)
                  }
                />
                {/* {headOfficeError && <Text error>{headOfficeError}</Text>} */}
              </Grid>
              <Grid item xs={12}>
                <Tags
                  multiple
                  options={responsiblesList}
                  tags={responsibles}
                  placeholder='Responsables Départment'
                  getTags={(responsibles) => {
                    props.departmentProfilePropsChanged(
                      'responsibles',
                      responsibles
                    );
                  }}
                  handleClickAdd={() => {
                    setOpenUserModal(!openUserModal);
                    setUserType('responsible');
                  }}
                ></Tags>
                {responsiblesError && <Text error>{responsiblesError}</Text>}
              </Grid>
              <Grid item xs={12}>
                <Tags
                  multiple={false}
                  options={presidentsList}
                  tags={president}
                  placeholder='Président Départment'
                  getTags={(president) => {
                    props.departmentProfilePropsChanged('president', president);
                    if (president) {
                      props.departmentProfileFilterUsersList(
                        'president',
                        president,
                        vicePresidentsListFilter
                      );
                    }
                  }}
                  handleClickAdd={() => {
                    setOpenUserModal(!openUserModal);
                    setUserType('president');
                  }}
                ></Tags>
              </Grid>
              <Grid item xs={12}>
                <Tags
                  multiple={false}
                  options={vicePresidentsList}
                  tags={vicePresident}
                  placeholder='Vice Président Départment'
                  getTags={(vicePresident) => {
                    props.departmentProfilePropsChanged(
                      'vicePresident',
                      vicePresident
                    );
                    if (vicePresident) {
                      props.departmentProfileFilterUsersList(
                        'vicePresident',
                        vicePresident,
                        presidentsListFilter
                      );
                    }
                  }}
                  handleClickAdd={() => {
                    setOpenUserModal(!openUserModal);
                    setUserType('vicePresident');
                  }}
                ></Tags>
              </Grid>

              <Grid
                item
                xs={12}
                container
                alignItems='flex-end'
                justify='flex-end'
              >
                {messageError && <Text error>{messageError}</Text>}
              </Grid>
            </Grid>
          </div>
          <div className='main-footer'>
            <Button onClick={handleClickCancel} width={180} margin={10}>
              Annuler
            </Button>
            <Button onClick={handleClickSave} width={180} margin={10}>
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                textButton
              )}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => departmentProfileStyles()}
`;

const mapStateToProps = ({ departmentProfile }: RootState) => {
  return {
    regionsList: departmentProfile.regionsList,
    responsiblesList: departmentProfile.responsiblesList,
    presidentsList: departmentProfile.presidentsList,
    vicePresidentsList: departmentProfile.vicePresidentsList,
    presidentsListFilter: departmentProfile.presidentsListFilter,
    vicePresidentsListFilter: departmentProfile.vicePresidentsListFilter,

    logo: departmentProfile.logo,
    name: departmentProfile.name,
    headOffice: departmentProfile.headOffice,
    region: departmentProfile.region,
    president: departmentProfile.president,
    vicePresident: departmentProfile.vicePresident,
    responsibles: departmentProfile.responsibles,

    nameError: departmentProfile.nameError,
    headOfficeError: departmentProfile.headOfficeError,
    responsiblesError: departmentProfile.responsiblesError,
    messageError: departmentProfile.messageError,
    getDepartmentMessageError: departmentProfile.getDepartmentMessageError,
    title: departmentProfile.title,
    textButton: departmentProfile.textButton,
    loadingPage: departmentProfile.loadingPage,
    loading: departmentProfile.loading,
  };
};

export const connector = connect(mapStateToProps, {
  departmentProfileGetAllList,
  departmentProfileFilterUsersList,
  departmentProfileGetDepartment,
  departmentProfilePropsChanged,
  departmentProfileCreateDepartment,
  departmentProfileUpdateDepartment,
  departmentProfileIntialState,
});

export default connector(withSnackbar(DepartmentProfile));

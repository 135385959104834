import { css } from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const departmentProfileStyles = () => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100vh; */
    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 640px;
      width: 1000px;
      margin: 20px;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
      border-radius: 20px;
      .main-header {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* margin: 20px; */
        /* padding: 10px; */
      }
      .main-content {
        width: 600px;
        /* flex: 2; */
        display: flex;
        flex-direction: column;
        /* padding: 10px; */
      }
      .main-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 10px;
      }

      /* .text-field-select {
        border-radius: 30px;
      } */
    }
  `;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // flexGrow: 1,
      margin: '10px 0',
    },

    circularProgress: {
      color: '#ff5078',
    },
  })
);

import {
  DepartmentApiResponse,
  RegionApiResponse,
  UserApiResponse,
} from '../../actions/typesApi';
import { isEmpty, isEmail } from '../../utils/validations';
import { AddDepartmentState } from '../AddDepartment/addDepartment.types';
import { DepartmentProfileState } from './departmentProfile.types';

export const departmentProfileGetDepartmentObject = (
  department: DepartmentApiResponse
) => {
  let newDepartment: any = Object.assign({}, department);

  newDepartment = {
    _id: department._id,
    region: department.region && {
      _id: department.region._id,
      name: department.region.name,
    },
    type: department.type,
    name: department.name,
    headOffice: department.headOffice,
    logo: department.logo,
    president: department.president && {
      _id: department.president._id,
      name: `${department.president.firstName} ${department.president.lastName}`,
    },
    vicePresident: department.vicePresident && {
      _id: department.vicePresident._id,
      name: `${department.vicePresident.firstName} ${department.vicePresident.lastName}`,
    },

    responsibles: department.responsibles.map((responsible: any) => ({
      _id: responsible._id,
      name: `${responsible.firstName} ${responsible.lastName}`,
    })),
  };

  return newDepartment;
};

export const validateFieldsDepartmentProfile = (
  props: DepartmentProfileState | AddDepartmentState
) => {
  let newProps: DepartmentProfileState | AddDepartmentState = Object.assign(
    {},
    props
  );

  if (isEmpty(newProps.name)) {
    newProps.nameError = 'Nom département est obligatoire';
  } else {
    newProps.nameError = '';
  }

  //   if (isEmpty(newProps.headOffice)) {
  //     newProps.headOfficeError = 'Siége social est obligatoire';
  //   } else {
  //     newProps.headOfficeError = '';
  //   }

  if (newProps.responsibles.length === 0) {
    newProps.responsiblesError =
      'Département doit avoir au moins un responsable';
  } else {
    newProps.responsiblesError = '';
  }

  let valid = false;
  if (newProps.nameError === '' && newProps.responsiblesError === '') {
    valid = true;
  }

  return { newProps, valid };
};

export const departmentProfileObject = (
  department: DepartmentProfileState | AddDepartmentState
) => {
  let formData = new FormData();

  let responsibles: any = [];

  department.responsibles.forEach((responsible) => {
    responsibles.push(responsible._id);
  });

  if (department.name && responsibles.length !== 0) {
    formData.append('type', 'department');
    department.region && formData.append('parentId', department.region._id);
    formData.append('name', department.name);
    formData.append('headOffice', department.headOffice);
    department.president &&
      formData.append('president', department.president._id);
    department.vicePresident &&
      formData.append('vicePresident', department.vicePresident._id);
    formData.append('responsibles', JSON.stringify(responsibles));
    department.logo && formData.append('logo', department.logo);

    return formData;
  }
  return null;
};

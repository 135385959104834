import {
  REGIONS_DEPARTMENTS_PROPS_CHANGED,
  REGIONS_DEPARTMENTS_GET_LIST_SUCCESS,
  REGIONS_DEPARTMENTS_GET_LIST_FAILED,
  REGIONS_DEPARTMENTS_GET_LIST_FILTER,
  REGIONS_DEPARTMENTS_DELETE_SUCCESS,
  REGIONS_DEPARTMENTS_DELETE_FAILED,
  REGIONS_DEPARTMENTS_OFFLINE_SUCCESS,
  REGIONS_DEPARTMENTS_OFFLINE_FAILED,
  REGIONS_DEPARTMENTS_INITIAL_STATE,
} from '../actions/types';
import {
  RegionsDepartmentsState,
  RegionsDepartmentsActionTypes,
} from '../modules/RegionsDepartments/regionsDepartments.types';

const INITIAL_STATE: RegionsDepartmentsState = {
  regionsDepartments: [],
  regionsDepartmentsFilter: [],
  search: '',
  messageError: '',
  loading: true,
};

export default (
  state = INITIAL_STATE,
  action: RegionsDepartmentsActionTypes
): RegionsDepartmentsState => {
  switch (action.type) {
    case REGIONS_DEPARTMENTS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };
    case REGIONS_DEPARTMENTS_GET_LIST_SUCCESS:
      return {
        ...state,
        regionsDepartments: action.payload,
        regionsDepartmentsFilter: action.payload,
        loading: false,
      };
    case REGIONS_DEPARTMENTS_GET_LIST_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case REGIONS_DEPARTMENTS_GET_LIST_FILTER: {
      return {
        ...state,
        search: action.search,
        regionsDepartments: action.payload,
        loading: false,
      };
    }

    case REGIONS_DEPARTMENTS_DELETE_SUCCESS:
      return { ...state, regionsDepartments: action.payload, loading: false };
    case REGIONS_DEPARTMENTS_DELETE_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case REGIONS_DEPARTMENTS_OFFLINE_SUCCESS:
      return { ...state, regionsDepartments: action.payload, loading: false };
    case REGIONS_DEPARTMENTS_OFFLINE_FAILED:
      return { ...state, messageError: action.payload, loading: false };

    case REGIONS_DEPARTMENTS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

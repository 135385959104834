import React from 'react';
import { connect } from 'react-redux';
//Material
import { CircularProgress } from '@material-ui/core';
//Style
import styled from 'styled-components';
import { forgotPasswordStyles } from './forgotPassword.styles';
//Types
import { ForgotPasswordFormProps } from './forgotPassword.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';

//Actions
import {
  forgotPasswordPropsChanged,
  forgotPasswordConfirm,
  forgotPasswordInitialState,
} from '../../actions/forgotPasswordActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { isEmail, isEmpty } from '../../utils/validations';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';

const ForgotPassword: React.FC<ForgotPasswordFormProps> = (props) => {
  const {
    email,
    emailError,
    loading,
    onSaveChanged,
    snackbarShowMessage,
  } = props;

  React.useEffect(() => {
    props.forgotPasswordInitialState();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.forgotPasswordPropsChanged(event.target.name, event.target.value);
  };

  const handleClickConfirm = () => {
    if (isEmpty(email)) {
      props.forgotPasswordPropsChanged('emailError', 'Email est obligatoire');
    } else if (!isEmail(email)) {
      props.forgotPasswordPropsChanged('emailError', 'Email invalide');
    } else {
      props.forgotPasswordPropsChanged('emailError', '');
      props.forgotPasswordConfirm(email, () => {
        snackbarShowMessage(
          'Votre e-mail a été envoyé avec succès, veuillez vérifier votre boîte e-mail'
        );
        setTimeout(() => {
          onSaveChanged();
        }, 2000);
      });
    }
  };

  return (
    <Wrapper>
      <div className='main-container'>
        <Text lineHeight={26} className='title'>
          Entrez votre adresse e-mail et nous vous enverrons un lien de
          réinitialisation du mot de passe.
        </Text>

        <Input
          value={email}
          type='text'
          name='email'
          placeholder='Saisissez votre adresse email'
          width={360}
          margin='10px 0'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleEventChange(e)
          }
        />
        {emailError && <Text error>{emailError}</Text>}
      </div>

      <Button onClick={handleClickConfirm} margin={12}>
        {loading ? <CircularProgress size={20} color='inherit' /> : 'Confirmer'}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => forgotPasswordStyles()}
`;

const mapStateToProps = ({ forgotPassword }: RootState) => {
  const { email, emailError, loading } = forgotPassword;
  return {
    email,
    emailError,
    loading,
  };
};

export const connector = connect(mapStateToProps, {
  forgotPasswordPropsChanged,
  forgotPasswordConfirm,
  forgotPasswordInitialState,
});

export default connector(withSnackbar(ForgotPassword));

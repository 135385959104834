import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Material
import { CircularProgress } from '@material-ui/core';
//Styles
import styled from 'styled-components';
import { loginStyles } from './login.styles';
//Types
import { LoginFormProps } from './login.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import ForgotPasswpordModal from '../../components/Modal';
//Modules
import ForgotPassword from '../ForgotPassword';
//Assets/Images
import logo from '../../assets/images/logo.png';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_remove_red_eye } from 'react-icons-kit/md/ic_remove_red_eye';
import { ic_visibility_off } from 'react-icons-kit/md/ic_visibility_off';
//Actions
import { loginPropsChanged, onLogin } from '../../actions/loginActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';

const Login: React.FC<LoginFormProps> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [visiblePassword, setVisiblePassword] = React.useState<boolean>(false);
  const token = getItemFromLocalStorage('token');

  if (token) {
    return <Redirect to={{ pathname: '/users' }} />;
  }

  const {
    email,
    password,
    emailError,
    passwordError,
    messageError,
    loading,
  } = props;
  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.loginPropsChanged(event.target.name, event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleClickLogin();
    }
  };

  const handleClickLogin = () => {
    props.onLogin(props, () => props.history.push('/users'));
    // props.history.push(
    //   '/reset-password/553658042adb4289c820428aae6fd125baf68585f23e12deee01c50233d445b5'
    // );
  };

  return (
    <Wrapper>
      <div className='card'>
        <ForgotPasswpordModal open={open} onClose={() => setOpen(!open)}>
          <ForgotPassword onSaveChanged={() => setOpen(!open)}></ForgotPassword>
        </ForgotPasswpordModal>
        <div className='card-left-col'>
          <img src={logo} alt='logo' />

          <Text fontWeight={600} fontSize={24} lineHeight={36}>
            BONJOUR!
          </Text>
          <Text lineHeight={26} style={{ textAlign: 'center' }}>
            Connectez-vous pour accéder à votre compte.
          </Text>
        </div>
        <hr className='divider' />

        <div className='card-right-col'>
          <Text fontWeight={600} fontSize={24} lineHeight={36}>
            SE CONNECTER
          </Text>
          <div className='div-inputs'>
            <Input
              value={email}
              type='text'
              name='email'
              placeholder='Email'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                handleKeyPress(e)
              }
              width={360}
              margin='10px 0'
            />

            {emailError && <Text error>{emailError}</Text>}

            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Input
                value={password}
                type={visiblePassword ? 'text' : 'password'}
                name='password'
                placeholder='Mot de passe'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleEventChange(e)
                }
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                  handleKeyPress(e)
                }
                width={360}
              />
              <Icon
                onClick={() => setVisiblePassword(!visiblePassword)}
                icon={visiblePassword ? ic_visibility_off : ic_remove_red_eye}
                size='24'
                style={{
                  position: 'absolute',
                  right: 10,
                  color: '#c0c0c0',
                  cursor: 'pointer',
                }}
              />
            </div>

            {passwordError && <Text error>{passwordError}</Text>}

            <Text
              fontSize={11}
              color='#c0c0c0'
              className='forgot-password-txt'
              onClick={() => setOpen(!open)}
            >
              Mot de passe oublié ?
            </Text>
          </div>

          {messageError && <Text error>{messageError}</Text>}

          <Button width={200} margin={12} onClick={handleClickLogin}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Se Connecter'
            )}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${(props) => loginStyles(props)}
`;

const mapStateToProps = ({ login }: RootState) => {
  return {
    email: login.email,
    password: login.password,
    emailError: login.emailError,
    passwordError: login.passwordError,
    messageError: login.messageError,
    loading: login.loading,
  };
};

export const connector = connect(mapStateToProps, {
  loginPropsChanged,
  onLogin,
});

export default connector(Login);

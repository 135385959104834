import axios from 'axios';
import { URL } from './configApi';
import {
  USERS_PROPS_CHANGED,
  USERS_GET_LIST_SUCCESS,
  USERS_GET_LIST_FAILED,
  USERS_GET_LIST_FILTER_USERS,
  USERS_DELETE_USER_SUCCESS,
  USERS_DELETE_USER_FAILED,
  USERS_INITIAL_STATE,
  AppThunk,
} from './types';

import { GetUsersApiResponse, UserApiResponse } from './typesApi';
import {
  UsersPropsChangedAction,
  UsersInitialStateAction,
} from '../modules/Users/users.types';

export const usersPropsChanged = (
  prop: string,
  value: any
): UsersPropsChangedAction => {
  return { type: USERS_PROPS_CHANGED, prop, value };
};

export const usersGetAllUsers = (): AppThunk => (dispatch) => {
  axios
    // .get(`${URL}/users?permission[ne]=admin&page=${page}&limit=${limit}`)
    .get(`${URL}/users?permission=read/write&permission=read`)
    .then(({ data }: { data: GetUsersApiResponse }) => {
      if (data.status === 'success') {
        dispatch({
          type: USERS_GET_LIST_SUCCESS,
          payload: data.response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: USERS_GET_LIST_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const usersGetFilterUsers = (
  search: string,
  users: Array<UserApiResponse>
): AppThunk => (dispatch) => {
  let usersFilter: Array<UserApiResponse> = Object.assign([], users);

  dispatch({
    type: USERS_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });

  const result = usersFilter.filter((item: UserApiResponse) => {
    return (
      item.firstName.toLocaleLowerCase().match(search.toLocaleLowerCase()) ||
      item.lastName.toLocaleLowerCase().match(search.toLocaleLowerCase())
    );
  });

  dispatch({
    type: USERS_GET_LIST_FILTER_USERS,
    search,
    payload: result,
  });
};

export const usersDeleteUser = (
  userId: string,
  users: Array<UserApiResponse>,
  callback: () => void
): AppThunk => (dispatch) => {
  dispatch({
    type: USERS_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });

  axios
    .delete(`${URL}/users/${userId}`)
    .then((response) => {
      let newUsersList: Array<UserApiResponse> = Object.assign([], users);
      if (response.status === 204) {
        dispatch({
          type: USERS_DELETE_USER_SUCCESS,
          payload: newUsersList.filter(
            (user: UserApiResponse) => user._id !== userId
          ),
        });
      }
      callback();
    })
    .catch((error) => {
      if (error.response.status === 404) {
        dispatch({
          type: USERS_DELETE_USER_FAILED,
          payload: 'Aucun utilisateur trouvé avec cet identifiant.',
        });
      }
    });
};

export const usersInitialState = (): UsersInitialStateAction => {
  return { type: USERS_INITIAL_STATE };
};

import { isEmpty, isEmail } from '../../utils/validations';
import { LoginState } from './login.types';

export const validateFieldsLogin = (props: LoginState) => {
  let newProps: LoginState = Object.assign({}, props);
  if (isEmpty(newProps.email)) {
    newProps.emailError = 'Email est obligatoire';
  } else if (!isEmail(newProps.email)) {
    newProps.emailError = 'Email invalide';
  } else {
    newProps.emailError = '';
  }

  if (isEmpty(newProps.password)) {
    newProps.passwordError = 'Password est obligatoire';
  } else {
    newProps.passwordError = '';
  }

  let valid = false;
  if (newProps.emailError === '' && newProps.passwordError === '') {
    valid = true;
  }

  return { newProps, valid };
};

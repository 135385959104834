import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
//Styles
import { useStyles } from './regionsDepartmentsRouter.styles';
//Icons
import { ic_location_on } from 'react-icons-kit/md/ic_location_on';
//Components
import SideBar from '../../components/Drawer';
//Modules
import RegionsDepartments from '../../modules/RegionsDepartments';
import Region from '../../modules/RegionProfile';
import Department from '../../modules/DepartmentProfile';
import NotFound from '../../modules/NotFound';

const RegionsDepartmentsRouter: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootAppRouter}>
      <SideBar title='Région | Départements' icon={ic_location_on}>
        <Switch>
          <Route
            path='/regions-departments'
            component={RegionsDepartments}
            exact
          />

          <Route path='/regions-departments/add-region' component={Region} />
          <Route
            path='/regions-departments/region/:regionId'
            component={Region}
          />

          <Route
            path='/regions-departments/add-department'
            component={Department}
            exact
          />

          <Route
            path='/regions-departments/department/:departmentId'
            component={Department}
            exact
          />

          <Route path='/regions-departments/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default RegionsDepartmentsRouter;

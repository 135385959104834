import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { ThemeProvider } from 'styled-components';
import thunk from 'redux-thunk';
import './App.css';
//Reducers
import reducers from './reducers';
//Utils
import { PrivateRoute } from './utils/navigation';
import { removeItemFromLocalStorage } from './utils/storage';

// MODULES
import NotFound from './modules/NotFound';
import Login from './modules/Login';
import ResetPassword from './modules/ResetPassword';
import Statistics from './modules/Statistics';
import AdminProfile from './modules/AdminProfile';
import UpdatePassword from './modules/UpdatePassword';
import AutomatedTexts from './modules/AutomatedTexts';
// import UserProfile from './modules/UserProfile';

//Routes
import UsersRouter from './routes/UsersRouter';
import RegionsDepartmentsRouter from './routes/RegionsDepartmentsRouter';

export const theme = {
  primary: '#FF7E04',
  secondary: '#DD0089',
  bluePrimary: '#1A2D35',
  white: '#ffffff',
};

const App: React.FC = (props) => {
  // removeItemFromLocalStorage('token');
  // removeItemFromLocalStorage('user');
  return (
    <Provider store={createStore(reducers, applyMiddleware(thunk))}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Redirect exact from='/' to='/login' />
            <Route path='/login' component={Login} exact />
            <Route
              path='/reset-password/:token'
              component={ResetPassword}
              exact
            />

            <PrivateRoute path='/statistics' component={Statistics} />
            <PrivateRoute path='/update-profile' component={AdminProfile} />
            <PrivateRoute path='/update-password' component={UpdatePassword} />
            {/* <PrivateRoute path='/users' component={Users} />
            <PrivateRoute path='/user' component={UserProfile} /> */}
            <PrivateRoute path='/users' component={UsersRouter} />
            <PrivateRoute
              path='/regions-departments'
              component={RegionsDepartmentsRouter}
            />

            <PrivateRoute path='/automated-texts' component={AutomatedTexts} />
            <Route path='/*' component={NotFound} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;

import axios from 'axios';
import { URL } from './configApi';
import {
  ADMIN_PROFILE_PROPS_CHANGED,
  ADMIN_PROFILE_PROPS_VALIDATION,
  GET_CURRENT_USER_FAILED,
  GET_CURRENT_USER_SUCCES,
  ADMIN_PROFILE_SUCCESS,
  ADMIN_PROFILE_FAILED,
  ADMIN_PROFILE_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  AdminProfileState,
  AdminProfilePropsChangedAction,
  AdminProfileInitialStateAction,
} from '../modules/AdminProfile/adminProfile.types';
import { GetUserApiResponse } from './typesApi';

import {
  validateFieldsAdminProfile,
  adminProfileObject,
} from '../modules/AdminProfile/adminProfile.utils';
import { setItemInLocalStorage } from '../utils/storage';

export const adminProfilePropsChanged = (
  prop: string,
  value: any
): AdminProfilePropsChangedAction => {
  return { type: ADMIN_PROFILE_PROPS_CHANGED, prop, value };
};

export const adminProfileGetCurrentUser = (): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/users/me`)
    .then(({ data }: { data: GetUserApiResponse }) => {
      if (data.status === 'success') {
        dispatch({
          type: GET_CURRENT_USER_SUCCES,
          payload: data.response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: GET_CURRENT_USER_FAILED,
          payload: 'Echec de la mise à jour du profil',
        });
      }
    });
};

export const adminProfileConfirm = (
  props: AdminProfileState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsAdminProfile(props);
  dispatch({ type: ADMIN_PROFILE_PROPS_VALIDATION, payload: newProps });
  const profile = adminProfileObject(newProps);

  if (valid && profile) {
    dispatch({
      type: ADMIN_PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    dispatch({
      type: ADMIN_PROFILE_PROPS_CHANGED,
      prop: 'loadingPage',
      value: true,
    });

    axios
      .put(`${URL}/users/update_my_account`, profile)
      .then(({ data }: { data: GetUserApiResponse }) => {
        if (data.status === 'success') {
          setItemInLocalStorage('user', data.response.data);
          dispatch({
            type: ADMIN_PROFILE_SUCCESS,
            payload: data.response.data,
          });
          callback();
        }
      })
      .catch((error) => {
        dispatch({
          type: ADMIN_PROFILE_FAILED,
          payload: 'error',
        });
      });
  }
};

export const adminProfileIntialState = (): AdminProfileInitialStateAction => {
  return { type: ADMIN_PROFILE_INITIAL_STATE };
};

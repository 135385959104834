import React from 'react';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Style
import styled from 'styled-components';
import { addUserStyles } from './addUser.styles';
//Types
import { AddUserFormProps } from './addUser.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';

//Actions
import {
  addUserPropsChanged,
  addUserConfirm,
  addUserInitialState,
} from '../../actions/addUserActions';
//Reducers
import { RootState } from '../../reducers';
//HOC
import { withSnackbar } from '../../hoc/snackBarHoc';
//Utils

const permissions = [
  {
    id: 'read/write',
    label: 'Lire et Ecrire',
  },
  {
    id: 'read',
    label: 'Lecture seulement',
  },
  // {
  //   id: 'speakers',
  //   label: 'Collaborators',
  // },
];

const AddUser: React.FC<AddUserFormProps> = (props) => {
  const {
    firstName,
    lastName,
    email,
    address,
    country,
    codePostal,
    phoneNumber,
    mobile,
    photo,
    permission,
    firstNameError,
    lastNameError,
    emailError,
    permissionError,
    messageError,
    loading,
    onSaveChanged,
    snackbarShowMessage,
    userType,
  } = props;

  React.useEffect(() => {
    props.addUserInitialState();
    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.addUserPropsChanged(event.target.name, event.target.value);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.addUserPropsChanged('permission', event.target.value);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    props.addUserPropsChanged('photo', file);
  };

  const handleClickSave = () => {
    props.addUserConfirm(userType, props, () => {
      snackbarShowMessage('Utilisateur est ajouté avec succès');
      setTimeout(() => {
        onSaveChanged();
      }, 2000);
    });
  };

  return (
    <Wrapper>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ alignSelf: 'flex-start' }}
      >
        Ajouter Utilisateur
      </Text>
      <div className='main-header'>
        <UploadImage
          id='userImage'
          image={photo}
          emptyImage={null}
          onChange={(e) => {
            handleFileChange(e);
          }}
        />
      </div>
      <div className='main-content'>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Input
              value={firstName}
              type='text'
              name='firstName'
              placeholder='Nom'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            {firstNameError && <Text error>{firstNameError}</Text>}
          </Grid>
          <Grid item xs={6}>
            <Input
              value={lastName}
              type='text'
              name='lastName'
              placeholder='Prénom'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            {lastNameError && <Text error>{lastNameError}</Text>}
          </Grid>
          <Grid item xs={12}>
            <Input
              value={email}
              type='text'
              name='email'
              placeholder='Email'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            {emailError && <Text error>{emailError}</Text>}
          </Grid>
          <Grid item xs={6}>
            <Input
              value={address}
              type='text'
              name='address'
              placeholder='Adresse'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              value={country}
              type='text'
              name='country'
              placeholder='Ville'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              value={codePostal}
              type='text'
              name='codePostal'
              placeholder='Code postal'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              value={phoneNumber}
              type='text'
              name='phoneNumber'
              placeholder='Numéro de téléphone'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              value={mobile}
              type='text'
              name='mobile'
              placeholder='Mobile'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: '10px' }}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel htmlFor='permission'>Permission</InputLabel>
                <Select
                  value={permission}
                  name='permission'
                  onChange={handleSelectChange}
                  label='Permission'
                  id='permission'
                  // inputProps={{ className: 'text-field-select' }}
                >
                  {permissions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Text color='#373737'>{option.label}</Text>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {permissionError && <Text error>{permissionError}</Text>}
          </Grid>
          <Grid item xs={6} container alignItems='center' justify='center'>
            {messageError && <Text error>{messageError}</Text>}
          </Grid>
        </Grid>

        <div className='main-footer'>
          <Button onClick={handleClickSave} width={180} margin={10}>
            {loading ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              'Ajouter'
            )}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => addUserStyles()}
`;

const mapStateToProps = ({ addUser }: RootState) => {
  return {
    firstName: addUser.firstName,
    lastName: addUser.lastName,
    email: addUser.email,
    address: addUser.address,
    country: addUser.country,
    codePostal: addUser.codePostal,
    phoneNumber: addUser.phoneNumber,
    mobile: addUser.mobile,
    photo: addUser.photo,
    permission: addUser.permission,
    firstNameError: addUser.firstNameError,
    lastNameError: addUser.lastNameError,
    emailError: addUser.emailError,
    permissionError: addUser.permissionError,
    messageError: addUser.messageError,
    loading: addUser.loading,
  };
};

export const connector = connect(mapStateToProps, {
  addUserPropsChanged,
  addUserConfirm,
  addUserInitialState,
});

export default connector(withSnackbar(AddUser));

import axios from 'axios';
import { URL } from './configApi';
import {
  USER_PROFILE_GET_USER_SUCCESS,
  USER_PROFILE_GET_USER_FAILED,
  USER_PROFILE_PROPS_CHANGED,
  USER_PROFILE_PROPS_VALIDATION,
  USER_PROFILE_CREATE_USER_SUCCESS,
  USER_PROFILE_CREATE_USER_FAILED,
  USER_PROFILE_UPDATE_USER_SUCCESS,
  USER_PROFILE_UPDATE_USER_FAILED,
  USER_PROFILE_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  UserProfileState,
  UserProfilePropsChangedAction,
  UserProfileInitialStateAction,
} from '../modules/UserProfile/userProfile.types';
import { GetUserApiResponse } from './typesApi';
//Utils
import {
  validateFieldsUserProfile,
  userProfileCreateUserObject,
  userProfileUpdateUserObject,
} from '../modules/UserProfile/userProfile.utils';

export const userProfileGetUser = (userId: string): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/users/${userId}`)
    .then(({ data }: { data: GetUserApiResponse }) => {
      if (data.status === 'success') {
        dispatch({
          type: USER_PROFILE_GET_USER_SUCCESS,
          payload: data.response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 404) {
        dispatch({
          type: USER_PROFILE_GET_USER_FAILED,
          payload: 'Aucun utilisateur trouvé avec cet identifiant.',
        });
      } else if (error.response.status === 500) {
        dispatch({
          type: USER_PROFILE_GET_USER_FAILED,
          payload: 'Identifiant Utiliateur est incorrect',
        });
      }
    });
};

export const userProfilePropsChanged = (
  prop: string,
  value: any
): UserProfilePropsChangedAction => {
  return { type: USER_PROFILE_PROPS_CHANGED, prop, value };
};

export const userProfileCreateUser = (
  props: UserProfileState,
  callback: () => void
): AppThunk => async (dispatch) => {
  const { newProps, valid } = validateFieldsUserProfile(props);
  dispatch({ type: USER_PROFILE_PROPS_VALIDATION, payload: newProps });
  const profile = userProfileCreateUserObject(newProps);

  if (valid && profile) {
    dispatch({
      type: USER_PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .post(`${URL}/users`, profile)
      .then(async ({ data }: { data: GetUserApiResponse }) => {
        if (data.status === 'success') {
          dispatch({
            type: USER_PROFILE_CREATE_USER_SUCCESS,
          });
        }

        callback();
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: USER_PROFILE_CREATE_USER_FAILED,
            payload: 'Utilisateur existe déjà.',
          });
        }
      });
  }
};

export const userProfileUpdateUser = (
  userId: string,
  props: UserProfileState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsUserProfile(props);
  dispatch({ type: USER_PROFILE_PROPS_VALIDATION, payload: newProps });
  const profile = userProfileUpdateUserObject(newProps);

  if (valid && profile) {
    dispatch({
      type: USER_PROFILE_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });

    axios
      .put(`${URL}/users/${userId}`, profile)
      .then(({ data }: { data: GetUserApiResponse }) => {
        if (data.status === 'success') {
          dispatch({
            type: USER_PROFILE_UPDATE_USER_SUCCESS,
            payload: data.response.data,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          dispatch({
            type: USER_PROFILE_UPDATE_USER_FAILED,
            payload: 'Aucun utilisateur trouvé avec cet identifiant.',
          });
        }
      });
  }
};

export const userProfileIntialState = (): UserProfileInitialStateAction => {
  return { type: USER_PROFILE_INITIAL_STATE };
};

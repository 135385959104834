import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
//Material

//Styles

import styled from 'styled-components';
import { regionsDepartmentsStyles } from './regionsDepartments.styles';
//Types
import { RegionsDepartmentsListProps } from './regionsDepartments.types';
//Commons
import Text from '../../commons/Text';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import EmptyData from '../../components/EmptyData';
import Table from '../../components/Tables/RegionsDepatmentsTable';
import Dialog from '../../components/Dialog';
//Modules

//Assets/Images
import emptyState from '../../assets/images/emptyState.svg';
//Actions
import {
  regionsDepartmentsGetAllRegionsDepartments,
  regionsDepartmentsPropsChanged,
  regionsDepartmentsGetListFilter,
  regionsDepartmentsDelete,
  regionsDepartmentsOffline,
  regionsDepartmentsInitialState,
} from '../../actions/regionsDepartmentsActions';
//Reducers
import { RootState } from '../../reducers';
//Hoc
import { withSnackbar } from '../../hoc/snackBarHoc';
//Utils

const RegionsDepartments: React.FC<any> = (props) => {
  const [page, setPage] = React.useState(0);
  const [limit, setlimitPage] = React.useState(10);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [openDialogOffline, setOpenDialogOffline] = React.useState(false);
  const [regionDepartmentId, setRegionDepartmentId] = React.useState('');
  const [regionDepartmentOffline, setRegionDepartmentOffline] = React.useState(
    false
  );
  const [regionDepartmentType, setRegionDepartmentType] = React.useState('');

  const {
    regionsDepartments,
    regionsDepartmentsFilter,
    search,
    messageError,
    loading,
  } = props;

  React.useEffect(() => {
    // props.usersGetAllUsers(page, limit);
    props.regionsDepartmentsGetAllRegionsDepartments();
    /* eslint-disable-next-line */
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setlimitPage(+event.target.value);
    setPage(0);
  };

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.regionsDepartmentsGetListFilter(
      event.target.value,
      regionsDepartmentsFilter
    );
    // props.regionsDepartmentsPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSearch = () => {
    props.regionsDepartmentsGetListFilter(search, regionsDepartmentsFilter);
  };

  const handleClickDelete = () => {
    const regionDepartmentMsg =
      regionDepartmentType === 'region'
        ? ' La région est supprimée'
        : ' Le département est supprimée';

    props.regionsDepartmentsDelete(
      regionDepartmentId,
      regionDepartmentType,
      regionsDepartments,
      () => {
        props.snackbarShowMessage(`${regionDepartmentMsg} avec succès`);
        setOpenDialogDelete(false);
      }
    );
  };

  const handleClickOffline = () => {
    const regionDepartmentMsg =
      regionDepartmentType === 'region'
        ? ' La région est mise à jour '
        : ' Le département est mis à jour';

    props.regionsDepartmentsOffline(
      regionDepartmentId,
      regionDepartmentOffline,
      regionDepartmentType,
      regionsDepartments,
      () => {
        props.snackbarShowMessage(`${regionDepartmentMsg} avec succès`);
        setOpenDialogOffline(false);
      }
    );
  };

  const handleClickOpenDialogDelete = (
    regionDepartmentId: string,
    regionDepartmentType: string
  ) => {
    setOpenDialogDelete(true);
    setRegionDepartmentId(regionDepartmentId);
    setRegionDepartmentType(regionDepartmentType);
  };

  const handleClickOpenDialogOffline = (
    regionDepartmentId: string,
    regionDepartmentOffline: boolean,
    regionDepartmentType: string
  ) => {
    setOpenDialogOffline(true);
    setRegionDepartmentId(regionDepartmentId);
    setRegionDepartmentOffline(regionDepartmentOffline);
    setRegionDepartmentType(regionDepartmentType);
  };

  const dialogSubjectType =
    regionDepartmentType === 'region' ? 'Région' : 'Département';

  const dialogContentType =
    regionDepartmentType === 'region' ? 'région' : 'département';

  const dialogOfflineSubject = regionDepartmentOffline
    ? 'Activer'
    : 'Désactiver';

  const dialogOffline = regionDepartmentOffline ? 'activer' : 'désactiver';

  return (
    <Wrapper>
      <div className='main-header'>
        <div className='div-input-search'>
          <Input
            value={search}
            type='text'
            name='search'
            placeholder='Rechercher'
            width={690}
            margin='10px 0'
            className='input-search'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleEventChange(e)
            }
          />
          <Button
            onClick={handleClickSearch}
            width={120}
            background='#373737'
            className='button-search'
          >
            Rechercher
          </Button>
        </div>

        <Button
          onClick={() => props.history.push('/regions-departments/add-region')}
          width={130}
          style={{ margin: '10px 0' }}
        >
          Ajouter Région
        </Button>
        <Button
          onClick={() =>
            props.history.push('/regions-departments/add-department')
          }
          width={150}
          color='#dd0089'
          border='1px solid #dd0089'
          background='#F6F9FD'
          style={{ margin: '10px 0' }}
        >
          Ajouter Départment
        </Button>
      </div>
      <Text
        fontWeight={600}
        fontSize={16}
        lineHeight={24}
        style={{ width: '1000px', marginTop: '30px' }}
      >
        Liste des Régions
      </Text>
      <div className='card'>
        <Dialog
          open={openDialogDelete}
          subject={`Supprimer ${dialogSubjectType}`}
          content={`Êtes-vous sûr de vouloir supprimer cet ${dialogContentType} ?`}
          agreeText='Supprimer'
          handleAgree={handleClickDelete}
          handleClose={() => setOpenDialogDelete(false)}
        ></Dialog>

        <Dialog
          open={openDialogOffline}
          subject={`${dialogOfflineSubject} ${dialogSubjectType}`}
          content={`Êtes-vous sûr de vouloir ${dialogOffline} cet ${dialogContentType} ?`}
          agreeText={dialogOffline}
          handleAgree={handleClickOffline}
          handleClose={() => setOpenDialogOffline(false)}
        ></Dialog>
        <EmptyData
          data={regionsDepartments}
          loading={loading}
          message='La liste des régions est vide'
          messageError={messageError}
          image={emptyState}
        >
          <Table
            data={regionsDepartments}
            // handleClickDepartments={handleClickDepartments}
            count={regionsDepartments.length}
            rowsPerPage={limit}
            page={page}
            changePage={handleChangePage}
            changeRowsPerPage={handleChangeRowsPerPage}
            clickUpdateRegion={(id) => {
              props.history.push(`/regions-departments/region/${id}`);
            }}
            clickUpdateDepartment={(id) => {
              props.history.push(`/regions-departments/department/${id}`);
            }}
            clickDelete={(regionDepartmentId, regionDepartmentType) => {
              handleClickOpenDialogDelete(
                regionDepartmentId,
                regionDepartmentType
              );
            }}
            clickOffline={(
              regionDepartmentId,
              regionDepartmentOffline,
              regionDepartmentType
            ) => {
              handleClickOpenDialogOffline(
                regionDepartmentId,
                regionDepartmentOffline,
                regionDepartmentType
              );
            }}
          ></Table>
        </EmptyData>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${() => regionsDepartmentsStyles()}
`;

const mapStateToProps = ({ regionsDepartments }: RootState) => {
  return {
    regionsDepartments: regionsDepartments.regionsDepartments,
    regionsDepartmentsFilter: regionsDepartments.regionsDepartmentsFilter,
    search: regionsDepartments.search,
    messageError: regionsDepartments.messageError,
    loading: regionsDepartments.loading,
  };
};

export const connector = connect(mapStateToProps, {
  regionsDepartmentsGetAllRegionsDepartments,
  regionsDepartmentsPropsChanged,
  regionsDepartmentsGetListFilter,
  regionsDepartmentsDelete,
  regionsDepartmentsOffline,
  regionsDepartmentsInitialState,
});

export default connector(withSnackbar(RegionsDepartments));

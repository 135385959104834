import axios from 'axios';
import { URL } from './configApi';
import {
  AUTOMATED_TEXTS_PROPS_CHANGED,
  AUTOMATED_TEXTS_GET_TEXTS_SUCCESS,
  AUTOMATED_TEXTS_GET_TEXTS_FAILED,
  AUTOMATED_TEXTS_UPDATE_TEXTS_SUCCESS,
  AUTOMATED_TEXTS_UPDATE_TEXTS_FAILED,
  AUTOMATED_TEXTS_INITIAL_STATE,
  AppThunk,
} from './types';

import { GetUsersApiResponse, UserApiResponse } from './typesApi';
import {
  AutomatedTextsPropsChangedAction,
  AutomatedTextsInitialStateAction,
} from '../modules/AutomatedTexts/automatedTexts.types';

export const automatedTextsPropsChanged = (
  prop: string,
  value: any
): AutomatedTextsPropsChangedAction => {
  return { type: AUTOMATED_TEXTS_PROPS_CHANGED, prop, value };
};

export const automatedTextsIntialState = (): AutomatedTextsInitialStateAction => {
  return { type: AUTOMATED_TEXTS_INITIAL_STATE };
};

export const automatedTextsGetAdminSettings = (): AppThunk => (dispatch) => {
  axios
    .get(`${URL}/admin/settings`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        dispatch({
          type: AUTOMATED_TEXTS_GET_TEXTS_SUCCESS,
          payload: data.response.data[0],
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: AUTOMATED_TEXTS_GET_TEXTS_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const automatedTextsUpdateTexts = (
  props: any,
  callback: () => void
): AppThunk => (dispatch) => {
  const data = {
    creationRegionDepartment: props.creationRegionDepartment,
    offlineRegionDepartment: props.offlineRegionDepartment,
    onlineRegionDepartment: props.onlineRegionDepartment,
    deleteRegionDepartment: props.deleteRegionDepartment,
  };

  axios
    .put(`${URL}/admin/settings`, data)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        dispatch({
          type: AUTOMATED_TEXTS_UPDATE_TEXTS_SUCCESS,
          payload: data.response.data,
        });
        callback();
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: AUTOMATED_TEXTS_UPDATE_TEXTS_FAILED,
          payload: 'Error',
        });
      }
    });
};

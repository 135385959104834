import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//Material
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularProgress } from '@material-ui/core';
//Icons
import { Icon } from 'react-icons-kit';
import { ic_control_point } from 'react-icons-kit/md/ic_control_point';
//Styles
import styled from 'styled-components';
import { automatedTextsStyles, useStyles } from './automatedTexts.styles';
//Types
import { AutomatedTextsFormProps } from './automatedTexts.types';
//Commons
import Text from '../../commons/Text';
import TextArea from '../../commons/Inputs/InputTextArea';
import Input from '../../commons/Inputs/Input';
import Button from '../../commons/Button';
//Components
import UploadImage from '../../components/UploadImage';
import Tags from '../../components/Tags';
import Modal from '../../components/Modal';
import SideBar from '../../components/Drawer';

//Modules
import AddUser from '../AddUser';
import AddDepartment from '../AddDepartment';
//Assets/Images
import camera from '../../assets/images/camera.png';
import { ic_message } from 'react-icons-kit/md/ic_message';

//Actions
import {
  automatedTextsPropsChanged,
  automatedTextsGetAdminSettings,
  automatedTextsUpdateTexts,
  automatedTextsIntialState,
} from '../../actions/automatedTextsActions';
//Reducers
import { RootState } from '../../reducers';
//Utils
import { getItemFromLocalStorage } from '../../utils/storage';
//hoc
import { withSnackbar } from '../../hoc/snackBarHoc';
import { AnyCnameRecord } from 'dns';

const AutomatedTexts: React.FC<any> = (props) => {
  const {
    creationRegionDepartment,
    onlineRegionDepartment,
    offlineRegionDepartment,
    deleteRegionDepartment,
    loading,
    messageError,
    // loadingCreationRegionDepartment,
    // loadingOfflineRegionDepartment,
    // loadingDeleteRegionDepartment,
  } = props;

  React.useEffect(() => {
    props.automatedTextsGetAdminSettings();

    /* eslint-disable-next-line */
  }, []);

  const handleEventChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.automatedTextsPropsChanged(event.target.name, event.target.value);
  };

  const handleClickSave = () => {
    props.automatedTextsUpdateTexts(props, () => {
      props.snackbarShowMessage('Mise à jour avec succès');
    });
  };

  return (
    <SideBar title='Textes Automatisés' icon={ic_message}>
      <Wrapper>
        <React.Fragment>
          <Text
            fontWeight={600}
            fontSize={16}
            lineHeight={24}
            style={{ width: '1000px' }}
          >
            Création Région / Département
          </Text>
          <div className='card'>
            <TextArea
              height={250}
              value={creationRegionDepartment}
              type='text'
              name='creationRegionDepartment'
              placeholder='Création Région / Département'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            ></TextArea>
            <Button
              onClick={handleClickSave}
              width={140}
              margin={10}
              style={{ alignSelf: 'flex-end' }}
            >
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                'Mise à jour'
              )}
            </Button>
          </div>
        </React.Fragment>

        <React.Fragment>
          <Text
            fontWeight={600}
            fontSize={16}
            lineHeight={24}
            style={{ width: '1000px' }}
          >
            Mise à jour Online
          </Text>
          <div className='card'>
            <TextArea
              value={onlineRegionDepartment}
              type='text'
              name='onlineRegionDepartment'
              placeholder='Mise à jour Région / Département online'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            <Button
              onClick={handleClickSave}
              width={140}
              margin={10}
              style={{ alignSelf: 'flex-end' }}
            >
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                'Mise à jour'
              )}
            </Button>
          </div>
        </React.Fragment>

        <React.Fragment>
          <Text
            fontWeight={600}
            fontSize={16}
            lineHeight={24}
            style={{ width: '1000px' }}
          >
            Mise à jour Offline
          </Text>
          <div className='card'>
            <TextArea
              value={offlineRegionDepartment}
              type='text'
              name='offlineRegionDepartment'
              placeholder='Mise à jour Région / Département offline'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            />
            <Button
              onClick={handleClickSave}
              width={140}
              margin={10}
              style={{ alignSelf: 'flex-end' }}
            >
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                'Mise à jour'
              )}
            </Button>
          </div>
        </React.Fragment>

        <React.Fragment>
          <Text
            fontWeight={600}
            fontSize={16}
            lineHeight={24}
            style={{ width: '1000px' }}
          >
            Suppression Région / Département
          </Text>
          <div className='card'>
            <TextArea
              value={deleteRegionDepartment}
              type='text'
              name='deleteRegionDepartment'
              placeholder='Suppression Région / Département'
              margin='10px 0'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEventChange(e)
              }
            ></TextArea>

            <Button
              onClick={handleClickSave}
              width={140}
              margin={10}
              style={{ alignSelf: 'flex-end' }}
            >
              {loading ? (
                <CircularProgress size={20} color='inherit' />
              ) : (
                'Mise à jour'
              )}
            </Button>
          </div>
        </React.Fragment>
      </Wrapper>
    </SideBar>
  );
};

const Wrapper = styled('div')`
  ${() => automatedTextsStyles()}
`;

const mapStateToProps = ({ automatedTexts }: RootState) => {
  return {
    creationRegionDepartment: automatedTexts.creationRegionDepartment,
    onlineRegionDepartment: automatedTexts.onlineRegionDepartment,
    offlineRegionDepartment: automatedTexts.offlineRegionDepartment,
    deleteRegionDepartment: automatedTexts.deleteRegionDepartment,
    loading: automatedTexts.loading,
    messageError: automatedTexts.messageError,
    // loadingCreationRegionDepartment:
    //   automatedTexts.loadingCreationRegionDepartment,
    // loadingOfflineRegionDepartment:
    //   automatedTexts.loadingOfflineRegionDepartment,
    // loadingDeleteRegionDepartment: automatedTexts.loadingDeleteRegionDepartment,
  };
};

export const connector = connect(mapStateToProps, {
  automatedTextsPropsChanged,
  automatedTextsGetAdminSettings,
  automatedTextsUpdateTexts,
  automatedTextsIntialState,
});

export default connector(withSnackbar(AutomatedTexts));

import { isEmpty, isEmail } from '../../utils/validations';
import { AdminProfileProps, AdminProfileState } from './adminProfile.types';

export const validateFieldsAdminProfile = (props: AdminProfileState) => {
  let newProps: AdminProfileState = Object.assign({}, props);

  if (isEmpty(newProps.firstName)) {
    newProps.firstNameError = 'Nom est obligatoire';
  } else {
    newProps.firstNameError = '';
  }

  if (isEmpty(newProps.lastName)) {
    newProps.lastNameError = 'Prénom est obligatoire';
  } else {
    newProps.lastNameError = '';
  }

  if (isEmpty(newProps.email)) {
    newProps.emailError = 'Email est obligatoire';
  } else if (!isEmail(newProps.email)) {
    newProps.emailError = 'Email invalide';
  } else {
    newProps.emailError = '';
  }

  let valid = false;
  if (
    newProps.firstNameError === '' &&
    newProps.lastNameError === '' &&
    newProps.emailError === ''
  ) {
    valid = true;
  }

  return { newProps, valid };
};

export const adminProfileObject = (profile: AdminProfileProps) => {
  let formData = new FormData();

  formData.append('firstName', profile.firstName);
  formData.append('lastName', profile.lastName);
  formData.append('email', profile.email);
  formData.append('address', profile.address);
  formData.append('country', profile.country);
  formData.append('codePostal', profile.codePostal);
  formData.append('phoneNumber', profile.phoneNumber);
  formData.append('mobile', profile.mobile);
  profile.photo && formData.append('photo', profile.photo);
  return formData;
};

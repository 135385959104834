import React from 'react';
//Material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
//Commons
import Text from '../../commons/Text';

export interface ResponsiveDialogProps {
  open: boolean;
  subject: string;
  content: string;
  agreeText: string;
  handleAgree: () => void;
  handleClose: () => void;
}

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { open, subject, content, handleAgree, handleClose, agreeText } = props;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>{subject}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleAgree} color='primary' autoFocus>
          {agreeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ResponsiveDialog;

import axios from 'axios';
import { URL } from './configApi';
import {
  REGIONS_DEPARTMENTS_PROPS_CHANGED,
  REGIONS_DEPARTMENTS_GET_LIST_SUCCESS,
  REGIONS_DEPARTMENTS_GET_LIST_FAILED,
  REGIONS_DEPARTMENTS_GET_LIST_FILTER,
  REGIONS_DEPARTMENTS_DELETE_SUCCESS,
  REGIONS_DEPARTMENTS_DELETE_FAILED,
  REGIONS_DEPARTMENTS_OFFLINE_SUCCESS,
  REGIONS_DEPARTMENTS_OFFLINE_FAILED,
  REGIONS_DEPARTMENTS_INITIAL_STATE,
  AppThunk,
} from './types';

import { GetUsersApiResponse, UserApiResponse } from './typesApi';
import {
  RegionsDepartmentsPropsChangedAction,
  RegionsDepartmentsInitialStateAction,
} from '../modules/RegionsDepartments/regionsDepartments.types';

export const regionsDepartmentsPropsChanged = (
  prop: string,
  value: any
): RegionsDepartmentsPropsChangedAction => {
  return { type: REGIONS_DEPARTMENTS_PROPS_CHANGED, prop, value };
};

export const regionsDepartmentsGetAllRegionsDepartments = (): AppThunk => (
  dispatch
) => {
  axios
    .get(`${URL}/region_department`)
    .then(({ data }: { data: any }) => {
      if (data.status === 'success') {
        dispatch({
          type: REGIONS_DEPARTMENTS_GET_LIST_SUCCESS,
          payload: data.response.data,
        });
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: REGIONS_DEPARTMENTS_GET_LIST_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const regionsDepartmentsGetListFilter = (
  search: string,
  regionsDepartments: Array<any>
): AppThunk => (dispatch) => {
  let regionsDepartmentsFilter: Array<UserApiResponse> = Object.assign(
    [],
    regionsDepartments
  );

  dispatch({
    type: REGIONS_DEPARTMENTS_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });

  const result = regionsDepartmentsFilter.filter((item: any) => {
    return item.name.toLocaleLowerCase().match(search.toLocaleLowerCase());
  });

  dispatch({
    type: REGIONS_DEPARTMENTS_GET_LIST_FILTER,
    search,
    payload: result,
  });
};

export const regionsDepartmentsDelete = (
  regionDepartmentId: string,
  regionDepartmentType: string,
  regionsDepartments: any,
  callback: () => void
): AppThunk => (dispatch) => {
  dispatch({
    type: REGIONS_DEPARTMENTS_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });

  axios
    .delete(`${URL}/region_department/${regionDepartmentId}`)
    .then((response) => {
      let newRegionsDepartmentsList: any = Object.assign(
        [],
        regionsDepartments
      );
      if (response.status === 204) {
        if (regionDepartmentType === 'region') {
          dispatch({
            type: REGIONS_DEPARTMENTS_DELETE_SUCCESS,
            payload: newRegionsDepartmentsList.filter(
              (region: any) => region._id !== regionDepartmentId
            ),
          });
        } else {
          dispatch(regionsDepartmentsGetAllRegionsDepartments());
          dispatch({
            type: REGIONS_DEPARTMENTS_PROPS_CHANGED,
            prop: 'loading',
            value: false,
          });
        }
      }

      callback();
    })
    .catch((error) => {
      if (error.response.status === 404) {
        const messageErrorRegionDepartmentType =
          regionDepartmentType === 'region' ? 'région' : 'département';
        dispatch({
          type: REGIONS_DEPARTMENTS_DELETE_FAILED,
          payload: `Aucun ${messageErrorRegionDepartmentType} trouvé avec cet identifiant.`,
        });
      }
      callback();
    });
};

export const regionsDepartmentsOffline = (
  regionDepartmentId: string,
  regionDepartmentOffline: boolean,
  regionDepartmentType: string,
  regionsDepartments: Array<any>,
  callback: () => void
): AppThunk => (dispatch) => {
  dispatch({
    type: REGIONS_DEPARTMENTS_PROPS_CHANGED,
    prop: 'loading',
    value: true,
  });
  axios
    .patch(`${URL}/region_department/${regionDepartmentId}`, {
      offline: !regionDepartmentOffline,
    })
    .then((response: any) => {
      let newRegionsDepartmentsList: any = Object.assign(
        [],
        regionsDepartments
      );

      if (response.status === 200) {
        if (regionDepartmentType === 'region') {
          const region = regionsDepartments.find(
            (item: any) => item._id === regionDepartmentId
          );
          if (region) {
            region.offline = !regionDepartmentOffline;
          }
          newRegionsDepartmentsList = regionsDepartments;
          dispatch({
            type: REGIONS_DEPARTMENTS_OFFLINE_SUCCESS,
            payload: newRegionsDepartmentsList,
          });
        } else {
          dispatch(regionsDepartmentsGetAllRegionsDepartments());
          dispatch({
            type: REGIONS_DEPARTMENTS_PROPS_CHANGED,
            prop: 'loading',
            value: false,
          });
        }
      }
      callback();
    })
    .catch((error) => {
      if (error.response.status === 404) {
        const messageErrorRegionDepartmentType =
          regionDepartmentType === 'region' ? 'région' : 'département';
        dispatch({
          type: REGIONS_DEPARTMENTS_OFFLINE_FAILED,
          payload: `Aucun ${messageErrorRegionDepartmentType} trouvé avec cet identifiant.`,
        });
        callback();
      }
    });
};

export const regionsDepartmentsInitialState = (): RegionsDepartmentsInitialStateAction => {
  return { type: REGIONS_DEPARTMENTS_INITIAL_STATE };
};

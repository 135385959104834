import {
  DepartmentApiResponse,
  RegionApiResponse,
  UserApiResponse,
} from '../../actions/typesApi';
import { isEmpty, isEmail } from '../../utils/validations';
import { RegionProfileState } from './regionProfile.types';

export const regionProfileGetRegionObject = (region: RegionApiResponse) => {
  let newRegion: any = Object.assign({}, region);

  newRegion = {
    _id: region._id,
    parentId: region.parentId,
    type: region.type,
    name: region.name,
    headOffice: region.headOffice,
    logo: region.logo,
    president: region.president && {
      _id: region.president._id,
      name: `${region.president.firstName} ${region.president.lastName}`,
    },
    vicePresident: region.vicePresident && {
      _id: region.vicePresident._id,
      name: `${region.vicePresident.firstName} ${region.vicePresident.lastName}`,
    },

    responsibles: region.responsibles.map((responsible) => ({
      _id: responsible._id,
      name: `${responsible.firstName} ${responsible.lastName}`,
    })),
    departments: region.departments
      ? region.departments.map((department) => ({
          _id: department._id,
          name: department.name,
        }))
      : null,
  };

  return newRegion;
};

export const validateFieldsRegionProfile = (props: RegionProfileState) => {
  let newProps: RegionProfileState = Object.assign({}, props);

  if (isEmpty(newProps.name)) {
    newProps.nameError = 'Nom région est obligatoire';
  } else {
    newProps.nameError = '';
  }

  //   if (isEmpty(newProps.headOffice)) {
  //     newProps.headOfficeError = 'Siége social est obligatoire';
  //   } else {
  //     newProps.headOfficeError = '';
  //   }

  if (newProps.responsibles.length === 0) {
    newProps.responsiblesError = 'Région doit avoir au moins un responsable';
  } else {
    newProps.responsiblesError = '';
  }

  if (newProps.departments.length === 0) {
    newProps.departmentsError = 'Région doit avoir au moins un département';
  } else {
    newProps.departmentsError = '';
  }

  let valid = false;
  if (
    newProps.nameError === '' &&
    newProps.responsiblesError === '' &&
    newProps.departmentsError === ''
  ) {
    valid = true;
  }

  return { newProps, valid };
};

export const regionProfileObject = (region: RegionProfileState) => {
  let formData = new FormData();

  let responsibles: any = [];
  let departments: any = [];

  region.responsibles.forEach((responsible) => {
    responsibles.push(responsible._id);
  });

  region.departments.forEach((department) => {
    departments.push(department._id);
  });

  if (region.name && responsibles.length !== 0 && departments.length !== 0) {
    formData.append('type', 'region');
    formData.append('name', region.name);
    formData.append('headOffice', region.headOffice);
    region.president && formData.append('president', region.president._id);
    region.vicePresident &&
      formData.append('vicePresident', region.vicePresident._id);
    formData.append('responsibles', JSON.stringify(responsibles));
    formData.append('departments', JSON.stringify(departments));
    region.logo && formData.append('logo', region.logo);

    return formData;
  }
  return null;
};

import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers';
import { Action } from 'redux';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

//LOGIN
export const LOGIN_PROPS_CHANGED = 'login_props_changed';
export const LOGIN_PROPS_VALIDATION = 'login_props_validation';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAILED = 'login_failed';
export const LOGIN_INITIAL_STATE = 'login_initial_state';

//FORGOT PASSWORD
export const FORGOT_PASSWORD_PROPS_CHANGED = 'forgot_password_props_changed';
export const FORGOT_PASSWORD_SUCCESS = 'forgot_password_success';
export const FORGOT_PASSWORD_FAILED = 'forgot_password_failed';
export const FORGOT_PASSWORD_INITIAL_STATE = 'forgot_password_initial_state';

//RESET PASSWORD
export const RESET_PASSWORD_PROPS_CHANGED = 'reset_password_props_changed';
export const RESET_PASSWORD_PROPS_VALIDATION =
  'reset_password_props_validation';
export const RESET_PASSWORD_VERIFICATION_TOKEN_FAILED =
  'reset_password_verification_token_failed';
export const RESET_PASSWORD_VERIFICATION_TOKEN_SUCCESS =
  'reset_password_verification_token_success';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_FAILED = 'reset_password_failed';
export const RESET_PASSWORD_INITIAL_STATE = 'reset_password_initial_state';

//UPDATE PASSWORD
export const UPDATE_PASSWORD_PROPS_CHANGED = 'update_password_props_changed';
export const UPDATE_PASSWORD_PROPS_VALIDATION =
  'update_password_props_validation';
export const UPDATE_PASSWORD_SUCCESS = 'update_password_success';
export const UPDATE_PASSWORD_FAILED = 'update_password_failed';
export const UPDATE_PASSWORD_INITIAL_STATE = 'update_password_initial_state';

//ADMIN PROFILE
export const ADMIN_PROFILE_PROPS_CHANGED = 'admin_profile_props_changed';
export const ADMIN_PROFILE_PROPS_VALIDATION = 'admin_profile_props_validation';
export const GET_CURRENT_USER_FAILED = 'get_current_user_failed';
export const GET_CURRENT_USER_SUCCES = 'get_current_user_succes';
export const ADMIN_PROFILE_SUCCESS = 'admin_profile_success';
export const ADMIN_PROFILE_FAILED = 'admin_profile_failed';
export const ADMIN_PROFILE_INITIAL_STATE = 'admin_profile_initial_state';

//USERS
export const USERS_PROPS_CHANGED = 'users_props_changed';
export const USERS_GET_LIST_SUCCESS = 'users_get_list_success';
export const USERS_GET_LIST_FAILED = 'users_get_list_failed';
export const USERS_GET_LIST_FILTER_USERS = 'users_get_list_filter_users';
export const USERS_DELETE_USER_SUCCESS = 'users_delete_user_success';
export const USERS_DELETE_USER_FAILED = 'users_delete_user_failed';
export const USERS_INITIAL_STATE = 'users_initial_state';
//USER PROFILE
export const USER_PROFILE_GET_USER_SUCCESS = 'user_profile_get_user_success';
export const USER_PROFILE_GET_USER_FAILED = 'user_profile_get_user_failed';
export const USER_PROFILE_PROPS_CHANGED = 'user_profile_props_changed';
export const USER_PROFILE_PROPS_VALIDATION = 'user_profile_props_validation';
export const USER_PROFILE_CREATE_USER_SUCCESS =
  'user_profile_create_user_success';
export const USER_PROFILE_CREATE_USER_FAILED =
  'user_profile_create_user_failed';
export const USER_PROFILE_UPDATE_USER_SUCCESS =
  'user_profile_update_user_success';
export const USER_PROFILE_UPDATE_USER_FAILED =
  'user_profile_update_user_failed';
export const USER_PROFILE_INITIAL_STATE = 'user_profile_initial_state';

//REGIONS DEPARTMENTS
export const REGIONS_DEPARTMENTS_PROPS_CHANGED =
  'regions_departments_props_changed';
export const REGIONS_DEPARTMENTS_GET_LIST_SUCCESS =
  'regions_departments_get_list_success';
export const REGIONS_DEPARTMENTS_GET_LIST_FAILED =
  'regions_departments_get_list_failed';
export const REGIONS_DEPARTMENTS_GET_LIST_FILTER =
  'regions_departments_get_list_filter';
export const REGIONS_DEPARTMENTS_DELETE_SUCCESS =
  'regions_departments_delete_success';
export const REGIONS_DEPARTMENTS_DELETE_FAILED =
  'regions_departments_delete_failed';
export const REGIONS_DEPARTMENTS_OFFLINE_SUCCESS =
  'regions_departments_offline_success';
export const REGIONS_DEPARTMENTS_OFFLINE_FAILED =
  'regions_departments_offline_failed';
export const REGIONS_DEPARTMENTS_INITIAL_STATE =
  'regions_departments_initial_state';

//REGION PROFILE
export const REGION_GET_All_LIST_SUCCESS = 'region_get_all_list_success';
export const REGION_GET_All_LIST_FAILED = 'region_get_all_list_failed';
export const REGION_PROFILE_FILTER_USERS_LIST_CHANGED =
  'region_profile_filter_users_list_changed';

export const REGION_PROFILE_GET_REGION_SUCCESS =
  'region_profile_get_region_success';
export const REGION_PROFILE_GET_REGION_FAILED =
  'region_profile_get_region_failed';
export const REGION_PROFILE_PROPS_CHANGED = 'region_profile_props_changed';
export const REGION_PROFILE_PROPS_VALIDATION =
  'region_profile_props_validation';
export const REGION_PROFILE_CREATE_REGION_SUCCESS =
  'region_profile_create_region_success';
export const REGION_PROFILE_CREATE_REGION_FAILED =
  'region_profile_create_region_failed';
export const REGION_PROFILE_UPDATE_REGION_SUCCESS =
  'region_profile_update_region_success';
export const REGION_PROFILE_UPDATE_REGION_FAILED =
  'region_profile_update_region_failed';
export const REGION_PROFILE_INITIAL_STATE = 'region_profile_initial_state';

//REGION PROFILE
export const DEPARTMENT_GET_All_LIST_SUCCESS =
  'department_get_all_list_success';
export const DEPARTMENT_GET_All_LIST_FAILED = 'department_get_all_list_failed';
export const DEPARTMENT_PROFILE_FILTER_USERS_LIST_CHANGED =
  'department_profile_filter_users_list_changed';

export const DEPARTMENT_PROFILE_GET_DEPARTMENT_SUCCESS =
  'department_profile_get_department_success';
export const DEPARTMENT_PROFILE_GET_DEPARTMENT_FAILED =
  'department_profile_get_department_failed';
export const DEPARTMENT_PROFILE_PROPS_CHANGED =
  'department_profile_props_changed';
export const DEPARTMENT_PROFILE_PROPS_VALIDATION =
  'department_profile_props_validation';
export const DEPARTMENT_PROFILE_CREATE_DEPARTMENT_SUCCESS =
  'department_profile_create_department_success';
export const DEPARTMENT_PROFILE_CREATE_DEPARTMENT_FAILED =
  'department_profile_create_department_failed';
export const DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_SUCCESS =
  'department_profile_update_department_success';
export const DEPARTMENT_PROFILE_UPDATE_DEPARTMENT_FAILED =
  'department_profile_update_department_failed';
export const DEPARTMENT_PROFILE_INITIAL_STATE =
  'department_profile_initial_state';

//ADD USER
export const ADD_USER_PROPS_CHANGED = 'add_user__props_changed';
export const ADD_USER_PROPS_VALIDATION = 'add_user_props_validation';
export const REGION_DEPARTMENT_ADD_USER = 'region_department_add_user';
export const ADD_USER_FAILED = 'add_user_failed';
export const ADD_USER_INITIAL_STATE = 'add_user_initial_state';

//ADD DEPARTMENT
export const ADD_DEPARTMENT_GET_LIST_USERS = 'add_department_get_list_users';
export const ADD_DEPARTMENT_GET_LIST_FAILED = 'add_department_get_list_failed';
export const ADD_DEPARTMENT_FILTER_LIST_USERS_CHANGED =
  'add_department_filter_list_users_changed';

export const ADD_DEPARTMENT_PROPS_CHANGED = 'add_department_props_changed';
export const ADD_DEPARTMENT_PROPS_VALIDATION =
  'add_department_props_validation';
export const REGION_PROFILE_ADD_DEPARTMENT = 'region_profile_add_department';
export const ADD_DEPARTMENT_FAILED = 'add_department_failed';
export const ADD_DEPARTMENT_INITIAL_STATE = 'add_department_initial_state';

//AUTOMATED TEXTS
export const AUTOMATED_TEXTS_PROPS_CHANGED = 'automated_texts_props_changed';
export const AUTOMATED_TEXTS_GET_TEXTS_SUCCESS =
  'automated_texts_get_texts_success';
export const AUTOMATED_TEXTS_GET_TEXTS_FAILED =
  'automated_texts_get_texts_failed';
export const AUTOMATED_TEXTS_UPDATE_TEXTS_SUCCESS =
  'automated_texts_update_texts_success';
export const AUTOMATED_TEXTS_UPDATE_TEXTS_FAILED =
  'automated_texts_update_texts_failed';
export const AUTOMATED_TEXTS_INITIAL_STATE = 'automated_texts_initial_state';

import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
//Styles
import { useStyles } from './usersRouter.styles';
//Icons
import { ic_supervisor_account } from 'react-icons-kit/md/ic_supervisor_account';
//Components
import SideBar from '../../components/Drawer';
//Modules
import Users from '../../modules/Users';
import User from '../../modules/UserProfile';
import NotFound from '../../modules/NotFound';

const UsersRouter: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  return (
    <div className={classes.rootAppRouter}>
      <SideBar title='Utilisateurs' icon={ic_supervisor_account}>
        <Switch>
          <Route path='/users' component={Users} exact />
          <Route path='/users/add-user' component={User} />
          <Route path='/users/:userId' component={User} />
          <Route path='/users/*' component={NotFound} />
        </Switch>
      </SideBar>
    </div>
  );
};

export default UsersRouter;

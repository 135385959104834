import {
  ADD_DEPARTMENT_GET_LIST_USERS,
  ADD_DEPARTMENT_GET_LIST_FAILED,
  ADD_DEPARTMENT_FILTER_LIST_USERS_CHANGED,
  ADD_DEPARTMENT_PROPS_CHANGED,
  ADD_DEPARTMENT_PROPS_VALIDATION,
  ADD_DEPARTMENT_FAILED,
  REGION_DEPARTMENT_ADD_USER,
  ADD_DEPARTMENT_INITIAL_STATE,
} from '../actions/types';
import {
  AddDepartmentState,
  AddDepartmentActionTypes,
} from '../modules/AddDepartment/addDepartment.types';

const INITIAL_STATE: AddDepartmentState = {
  responsiblesList: [],
  presidentsList: [],
  vicePresidentsList: [],
  presidentsListFilter: [],
  vicePresidentsListFilter: [],
  logo: null,
  name: '',
  headOffice: '',
  president: null,
  vicePresident: null,
  responsibles: [],
  nameError: '',
  headOfficeError: '',
  responsiblesError: '',
  messageError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: AddDepartmentActionTypes
): AddDepartmentState => {
  switch (action.type) {
    case ADD_DEPARTMENT_GET_LIST_USERS:
      return {
        ...state,
        responsiblesList: action.allResponsiblesList,
        presidentsList: action.allPresidentsList,
        vicePresidentsList: action.allVicePresidentList,
        presidentsListFilter: action.allPresidentsList,
        vicePresidentsListFilter: action.allVicePresidentList,
      };

    case ADD_DEPARTMENT_FILTER_LIST_USERS_CHANGED: {
      let newUsersList: Array<any> = Object.assign([], action.payload);

      if (action.role === 'president') {
        return {
          ...state,
          vicePresidentsList: newUsersList.filter(
            (item) => item._id !== action.userSelect._id
          ),
        };
      } else {
        return {
          ...state,
          presidentsList: newUsersList.filter(
            (item) => item._id !== action.userSelect._id
          ),
        };
      }
    }

    case ADD_DEPARTMENT_GET_LIST_FAILED:
      return { ...state, messageError: action.payload };

    case ADD_DEPARTMENT_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case ADD_DEPARTMENT_PROPS_VALIDATION:
      return { ...state, ...action.payload, loading: false };

    case ADD_DEPARTMENT_FAILED:
      return {
        ...state,
        messageError: action.payload,
        loading: false,
      };

    case REGION_DEPARTMENT_ADD_USER: {
      if (action.userType === 'responsible') {
        return {
          ...state,
          responsiblesList: [...[action.payload], ...state.responsiblesList],
        };
      } else if (action.userType === 'president') {
        return {
          ...state,
          presidentsList: [...[action.payload], ...state.presidentsList],
          presidentsListFilter: [
            ...[action.payload],
            ...state.presidentsListFilter,
          ],
        };
      } else {
        return {
          ...state,
          vicePresidentsList: [
            ...[action.payload],
            ...state.vicePresidentsList,
          ],
          vicePresidentsListFilter: [
            ...[action.payload],
            ...state.vicePresidentsListFilter,
          ],
        };
      }
    }

    case ADD_DEPARTMENT_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

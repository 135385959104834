import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import ForgotPasswordReducer from './forgotPasswordReducer';
import ResetPasswordReducer from './resetPasswordReducer';
import UpdatePasswordReducer from './updatePasswordReducer';
import AdminProfileReducer from './adminProfileReducer';
import UsersReducer from './usersReducer';
import UserProfileReducer from './userProfileReducer';
import RegionsDepartmentsReducer from './regionsDepartmentsReducer';
import RegionProfileReducer from './regionProfileReducer';
import DepartmentProfileReducer from './departmentProfileReducer';
import AddUserReducer from './addUserReducer';
import AddDepartmentReducer from './addDepartmentReducer';
import AutomatedTextsReducer from './automatedTextsReducer';

const reducers = combineReducers({
  login: LoginReducer,
  forgotPassword: ForgotPasswordReducer,
  resetPassword: ResetPasswordReducer,
  updatePassword: UpdatePasswordReducer,
  adminProfile: AdminProfileReducer,
  users: UsersReducer,
  userProfile: UserProfileReducer,
  regionsDepartments: RegionsDepartmentsReducer,
  regionProfile: RegionProfileReducer,
  departmentProfile: DepartmentProfileReducer,
  addUser: AddUserReducer,
  addDepartment: AddDepartmentReducer,
  automatedTexts: AutomatedTextsReducer,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;

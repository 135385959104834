import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { emptyDataStyles } from './emptyData.styles';
//Commons
import Text from '../../commons/Text';

export type EmptyDataProps = {
  data: Array<any>;
  image?: string;
  loading?: boolean;
  message?: string;
  messageError?: string;
  children?: React.ReactNode | any;
};

const EmptyData: React.FC<EmptyDataProps> = (props) => {
  const { data, loading, image, message, messageError, children } = props;
  if (loading) {
    return (
      <Wrapper>
        <CircularProgress className='circular-progress' />
      </Wrapper>
    );
  } else if (data && data.length === 0 && !loading) {
    return (
      <Wrapper>
        <div className='img-container'>
          <img src={image} alt='empty-state' />
        </div>
        <Text fontWeight={600} fontSize={16} lineHeight={36} color='#373737'>
          {message}
        </Text>
      </Wrapper>
    );
  } else if (!loading && messageError) {
    return (
      <Wrapper>
        <Text fontWeight={600} fontSize={16} lineHeight={36} error>
          {messageError}
        </Text>
      </Wrapper>
    );
  }
  return children;
};

const Wrapper = styled('div')`
  ${() => emptyDataStyles()}
`;
export default EmptyData;

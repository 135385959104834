import { isEmpty, isEmail } from '../../utils/validations';
import { AddUserState } from '../AddUser/addUser.types';
import { UserProfileState } from './userProfile.types';

export const validateFieldsUserProfile = (
  props: UserProfileState | AddUserState
) => {
  let newProps: UserProfileState | AddUserState = Object.assign({}, props);

  if (isEmpty(newProps.firstName)) {
    newProps.firstNameError = 'Nom est obligatoire';
  } else {
    newProps.firstNameError = '';
  }

  if (isEmpty(newProps.lastName)) {
    newProps.lastNameError = 'Prénom est obligatoire';
  } else {
    newProps.lastNameError = '';
  }

  if (isEmpty(newProps.email)) {
    newProps.emailError = 'Email est obligatoire';
  } else if (!isEmail(newProps.email)) {
    newProps.emailError = 'Email invalide';
  } else {
    newProps.emailError = '';
  }

  if (isEmpty(newProps.permission)) {
    newProps.permissionError = 'Permission est obligatoire';
  } else {
    newProps.permissionError = '';
  }

  let valid = false;
  if (
    newProps.firstNameError === '' &&
    newProps.lastNameError === '' &&
    newProps.emailError === '' &&
    newProps.permissionError === ''
  ) {
    valid = true;
  }

  return { newProps, valid };
};

export const userProfileCreateUserObject = (
  profile: UserProfileState | AddUserState
) => {
  let formData = new FormData();

  if (
    profile.firstName &&
    profile.lastName &&
    profile.email &&
    profile.permission
  ) {
    formData.append('firstName', profile.firstName);
    formData.append('lastName', profile.lastName);
    formData.append('email', profile.email);
    formData.append('address', profile.address);
    formData.append('country', profile.country);
    formData.append('codePostal', profile.codePostal);
    formData.append('phoneNumber', profile.phoneNumber);
    formData.append('mobile', profile.mobile);
    formData.append('permission', profile.permission);
    profile.photo && formData.append('photo', profile.photo);
    return formData;
  }
  return null;
};

export const userProfileUpdateUserObject = (
  profile: UserProfileState | AddUserState
) => {
  let formData = new FormData();

  if (profile.firstName && profile.lastName && profile.email) {
    formData.append('firstName', profile.firstName);
    formData.append('lastName', profile.lastName);
    formData.append('address', profile.address);
    formData.append('country', profile.country);
    formData.append('codePostal', profile.codePostal);
    formData.append('phoneNumber', profile.phoneNumber);
    formData.append('mobile', profile.mobile);
    profile.photo && formData.append('photo', profile.photo);
    return formData;
  }
  return null;
};

import axios from 'axios';
import { URL } from './configApi';
import {
  ADD_DEPARTMENT_GET_LIST_USERS,
  ADD_DEPARTMENT_GET_LIST_FAILED,
  ADD_DEPARTMENT_FILTER_LIST_USERS_CHANGED,
  ADD_DEPARTMENT_PROPS_CHANGED,
  ADD_DEPARTMENT_PROPS_VALIDATION,
  REGION_PROFILE_ADD_DEPARTMENT,
  ADD_DEPARTMENT_FAILED,
  ADD_DEPARTMENT_INITIAL_STATE,
  AppThunk,
} from './types';
import {
  AddDepartmentState,
  AddDepartmentFilterListUsersChangedAction,
  AddDepartmentPropsChangedAction,
  AddDepartmentInitialStateAction,
} from '../modules/AddDepartment/addDepartment.types';
import { GetUserApiResponse, UserApiResponse } from './typesApi';
//Utils From UserProfile
import {
  validateFieldsDepartmentProfile,
  departmentProfileObject,
} from '../modules/DepartmentProfile/departmentProfile.utils';

export const getListUsers = (): AppThunk => (dispatch) => {
  const promise1 = axios.get(`${URL}/users?permission[eq]=read`);
  const promise2 = axios.get(`${URL}/users?permission[eq]=read/write`);
  const promise3 = axios.get(`${URL}/region_department/departments`);

  let usersWithPermissionRead: UserApiResponse[] = [];
  let responsibles: UserApiResponse[] = [];

  Promise.all([promise1, promise2, promise3])
    .then((values: any) => {
      usersWithPermissionRead = [
        ...values[0].data.response.data.map((user: UserApiResponse) => ({
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
        })),
      ];
      responsibles = [
        ...values[1].data.response.data.map((user: UserApiResponse) => ({
          _id: user._id,
          name: `${user.firstName} ${user.lastName}`,
        })),
      ];

      dispatch({
        type: ADD_DEPARTMENT_GET_LIST_USERS,
        allResponsiblesList: responsibles,
        allPresidentsList: usersWithPermissionRead,
        allVicePresidentList: usersWithPermissionRead,
      });
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: ADD_DEPARTMENT_GET_LIST_FAILED,
          payload: 'Network Error',
        });
      }
    });
};

export const addDepartmentFilterListUsers = (
  role: string,
  userSelect: any,
  usersList: Array<any>
): AddDepartmentFilterListUsersChangedAction => {
  return {
    type: ADD_DEPARTMENT_FILTER_LIST_USERS_CHANGED,
    role,
    userSelect,
    payload: usersList,
  };
};

export const addDepartmentPropsChanged = (
  prop: string,
  value: any
): AddDepartmentPropsChangedAction => {
  return { type: ADD_DEPARTMENT_PROPS_CHANGED, prop, value };
};

export const addDepartmentConfirm = (
  props: AddDepartmentState,
  callback: () => void
): AppThunk => (dispatch) => {
  const { newProps, valid } = validateFieldsDepartmentProfile(props);
  dispatch({ type: ADD_DEPARTMENT_PROPS_VALIDATION, payload: newProps });
  const department = departmentProfileObject(newProps);

  if (valid && department) {
    dispatch({
      type: ADD_DEPARTMENT_PROPS_CHANGED,
      prop: 'loading',
      value: true,
    });
    axios
      .post(`${URL}/region_department`, department)
      .then(({ data }: { data: any }) => {
        if (data.status === 'success') {
          //Dispatch to region profile reducer
          dispatch({
            type: REGION_PROFILE_ADD_DEPARTMENT,
            payload: {
              _id: data.response.data._id,
              name: data.response.data.name,
            },
          });
          dispatch({
            type: ADD_DEPARTMENT_PROPS_CHANGED,
            prop: 'loading',
            value: false,
          });
          callback();
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: ADD_DEPARTMENT_FAILED,
            payload: 'Department existe déjà.',
          });
        }
      });
  }
};

export const addDepartmentInitialState = (): AddDepartmentInitialStateAction => {
  return { type: ADD_DEPARTMENT_INITIAL_STATE };
};

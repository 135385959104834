import { css } from 'styled-components';

export const textAreaStyles = (props: any) => {
  let margin = props.margin ? props.margin : '5px 0';
  let width = props.width ? `${props.width}px` : '100%';
  let height = props.height ? `${props.height}px` : '100%';

  return css`
    resize: vertical;
    /* height: 100%; */
    max-height: 280px;
    height: ${height};
    min-height: 200px;
    width: ${width};
    color: rgb(0, 0, 0);
    padding-top: 15px;
    box-sizing: border-box;
    font-size: 16px;
    resize: none;
    line-height: 21px;
    background: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #c0c0c0;
    border-image: initial;
    border-radius: 20px;
    padding: 15px;
    margin: ${margin};
    outline: none;

    ::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #c0c0c0;
    }
    ::-moz-placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
    }
    &:focus {
      border: 1px solid #373737;
      box-shadow: 0px 0px 2px 1px rgba(55, 55, 55, 0.1);
    }
  `;
};

import { css } from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const resetPasswordStyles = () => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 640px;
      width: 1000px;
      margin: 20px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
      border-radius: 20px;
      .main-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 30px;
      }
    }
  `;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },

    circularProgress: {
      color: '#DD0089',
    },
  })
);

import { css } from 'styled-components';

export const regionsDepartmentsStyles = () => {
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100vh; */
    .main-header {
      display: flex;
      justify-content: space-between;
      width: 1000px;
      .div-input-search {
        /* flex: 1; */
        position: relative;
        .input-search {
          box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.08);
          border: none;
          border-radius: 60px;
        }
        .button-search {
          position: absolute;
          top: 12px;
          right: 4px;
          margin: 0;
          height: 40px;
        }
      }
    }
    .card {
      display: flex;
      height: 640px;
      width: 1000px;
      margin: 15px;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 2px rgba(55, 55, 55, 0.1);
      border-radius: 20px;
    }
  `;
};

import {
  AUTOMATED_TEXTS_PROPS_CHANGED,
  AUTOMATED_TEXTS_GET_TEXTS_SUCCESS,
  AUTOMATED_TEXTS_GET_TEXTS_FAILED,
  AUTOMATED_TEXTS_UPDATE_TEXTS_SUCCESS,
  AUTOMATED_TEXTS_UPDATE_TEXTS_FAILED,
  AUTOMATED_TEXTS_INITIAL_STATE,
} from '../actions/types';
import {
  AutomatedTextsState,
  AutomatedTextsActionTypes,
} from '../modules/AutomatedTexts/automatedTexts.types';

const INITIAL_STATE: AutomatedTextsState = {
  creationRegionDepartment: '',
  offlineRegionDepartment: '',
  onlineRegionDepartment: '',
  deleteRegionDepartment: ' ',
  messageError: '',
  loading: false,
};

export default (
  state = INITIAL_STATE,
  action: AutomatedTextsActionTypes
): AutomatedTextsState => {
  switch (action.type) {
    case AUTOMATED_TEXTS_PROPS_CHANGED:
      return { ...state, [action.prop]: action.value };

    case AUTOMATED_TEXTS_GET_TEXTS_SUCCESS:
      return {
        ...state,
        creationRegionDepartment: action.payload.creationRegionDepartment,
        onlineRegionDepartment: action.payload.onlineRegionDepartment,
        offlineRegionDepartment: action.payload.offlineRegionDepartment,
        deleteRegionDepartment: action.payload.deleteRegionDepartment,
      };

    case AUTOMATED_TEXTS_GET_TEXTS_FAILED:
      return {
        ...state,
        messageError: action.payload,
      };

    case AUTOMATED_TEXTS_UPDATE_TEXTS_SUCCESS:
      return { ...state, ...action.payload };

    case AUTOMATED_TEXTS_UPDATE_TEXTS_FAILED:
      return {
        ...state,
        messageError: action.payload,
      };

    case AUTOMATED_TEXTS_INITIAL_STATE:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
